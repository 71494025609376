import React, { useState, useContext } from 'react';
import '../../../Style/Records_addpatient.css';
import { Link, NavLink } from 'react-router-dom';
import Navbar from '../../Layout/Navbar';
import Footer from '../../Layout/Footer';
// import DatePicker from "react-datepicker";
// import { parsePhoneNumberFromString } from 'libphonenumber-js';
// import { Button } from 'bootstrap';
import Records_popup from './Records_popup.js';
import { RecordContext } from './RecordContext.js';
import { Context } from '../../../Context.js';

function Records_patientlist() {
    const { doctors } = useContext(RecordContext);
    const {username} = useContext(Context);
    

    return (
        <div>
            <Navbar />
            <div className="desktop-3-YKe my-5">
                <div className="auto-group-gffr-GWY my-5">

                    <Link to="/profile">
                        <div className="auto-group-3h2t-cPN my-5">
                            <img className="group-1261154072-k8C" src="./images/group-1261154072-2y2.png" alt='group-1261154072-2y2' />
                            <p className="back-rBE mb-0">Back</p>
                        </div>
                    </Link>
                    <div className="auto-group-7c9r-BjJ">
                        <div className="auto-group-2hh6-wCg">
                            <div className="frame-1261154254-H1e">
                                <img className="rectangle-39635-zwe" src="./images/rectangle-39634.png" alt='rectangle-39634' />
                                 <div className="aha-iMr">{username}</div> 
                            </div>
                            <div className="auto-group-sbhn-cy2">
                                <div className="group-1261154801-Mfi">
                                    <div className="group-1261154793-fwJ">
                                        <div className="auto-group-c2hj-cLk">
                                            <img className="vector-jwA" src="./images/record-icon-ac.png" alt='vector-x6Y' />
                                            <div className="records-4yS">Records</div>
                                        </div>
                                        <Link to="/personal">
                                            <div className="auto-group-5wv4-Nz8">
                                                <img className="icon-WaY" src="./images/icon.png" alt='icon' />
                                                <div className="personal-3aU">Personal</div>
                                            </div>
                                        </Link>
                                        <Link to="/medical">
                                            <div className="auto-group-yt4c-n2G">
                                                <img className="group-1261154885-7aL" src="./images/group-1261154885.png" alt='group-1261154885' />
                                                <div className="medical-Rb2">Medical</div>
                                            </div>
                                        </Link>
                                        <Link to='/lifestyle'>
                                            <div className="auto-group-kr4y-ZBS">
                                                <img className="vector-6BN" src="./images/vector-QTe.png" alt='vector-QTe' />
                                                <div className="life-style-pNG">Life Style</div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className="my-zwrrar-7cG">My Zwrrar</div>
                                <div className="group-1261154808-FCg">
                                    <div className="rectangle-39639-P3z">
                                    </div>
                                    <div className="my-programs-Vcp">My Programs</div>
                                    <img className="group-1261154897-Py6" src="./images/group-1261154897.png" alt='group-1261154897' />
                                </div>
                            </div>
                        </div>
                        <div className="group-1261154803-tur">
                            <div className="view-patient-QNQ">View Patient</div>
                            <div className="group-1261154804-LWx">
                                <img className="vector-Fdv" src="./images/vector-vFW.png" alt='vector-vFW' />
                                <input className="search-records-and-appointments-ZuW" placeholder='Search records and appointments' type='text' style={{ width: "100%" }}></input>
                                {/* <div className="search-records-and-appointments-ZuW">Search records and appointments</div> */}
                            </div>
                            <div className="records-Rgp">Records</div>
                            <div className="auto-group-nzxc-vtU">
                                <div className='text-center' style={{ cursor: 'pointer' }} >
                                    {/* <img className="carbon-add-filled-XNU" src="./images/carbon-add-filled.png" /> */}
                                    <div className="add-records-Ndz">
                                        <Records_popup />
                                    </div>
                                </div>
                                <div className='container-fluid '>
                                    <div className='row row-cols-lg-3 row-cols-md-2'>
                                        {/* ------- */}
                                        {doctors.map((pitem) => {
                                            return (
                                                <div className='col d-flex '>
                                                    <div class="card my-2 border-0  text-center " style={{ width: '44rem', height: '7rem', backgroundColor: '#AF22451A', }}>
                                                        <div class="">
                                                            <h3 class=" zw_text_color poppins-regular zw_16  ">{pitem.name}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        {/* ------- */}
                                    </div>
                                </div>
                            </div>
                            <div className="bookings-tsE">Bookings</div>
                            <div className="group-1261154957-Qqa">
                                <div className="group-1261154956-wac">
                                    <img className="union-VME" src="./images/union.png" alt='union' />
                                    <div className="no-appointments-1aU">No Appointments</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Records_patientlist