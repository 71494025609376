import React, { useState } from "react";
import "../Style/CareIndividuals.css";
import img11 from "../../src/assets/img/image 87.png";
import img12 from "../../src/assets/img/request-sent-svgrepo-com 1.svg";
import img19 from "../../src/assets/img/uncheck.svg";
import img20 from "../../src/assets/img/Star 1.png";
import img21 from "../../src/assets/img/indi-doctor-image.png";
import Navbar from "../Components/Layout/Navbar";
import Footer from "../Components/Layout/Footer";
import { Link } from "react-router-dom";
import Laboratory from "../../src/assets/img/Laboratory 1.png";
import Homevisit from "../../src/assets/img/homevisit.png";
import Nursevisit from "../../src/assets/img/nursevisit.png";
import Vitamin from "../../src/assets/img/Vitamin IV Drips 1.png";
import vaccination from "../../src/assets/img/Vaccination 2.png";
import Physiotherapist from "../../src/assets/img/Physiotherapist 1.png";
import Radiology from "../../src/assets/img/Radiology 1.png";
import Corporate from "../../src/assets/img/Corporate Wellness 1.png";
import Caregiver from "../../src/assets/img/Caregiver 1.png";
function CareIndividuals() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    const newIndex = currentIndex === cards.length - 1 ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const prevSlide = () => {
    const newIndex = currentIndex === 0 ? cards.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const indicard1data = [
    { title: "Laboratory", image: Laboratory, path: "/laboratory" },
    { title: "Home visit Doctor", image: Homevisit, path: "/doctorvisit" },
    { title: "Nurse visit", image: Nursevisit, path: "/nursevisit" },
    { title: "Vitamin IV Drips", image: Vitamin, path: "/vitamin" },
    { title: "Vaccination", image: vaccination, path: "/vaccination" },
    { title: "Physiotherapist", image: Physiotherapist, path: "/physiotherapist" },
    { title: "Radiology", image: Radiology, path: "/radiology" },
    { title: "Corporate Wellness", image: Corporate, path: "/corporate" },
    { title: "Caregiver", image: Caregiver, path: "/caregiver" },
  ];

  const indicard2data = [
    {
      title: "Easy treatment journey",
      image: "./images/careimg1.png",
      description: "Quick access to the doctors with different specialization. Text chat or video call, around the clock, with high privacy and confidentiality. Home visits and clinical examination without waiting in clinics for long hours."
    },
    {
      title: "Counseling and integrated care",
      image: "./images/careimg2.jpg",
      description: "A suitable diagnosis of your condition, issuance of prescriptions and e-medical reports, and dispensing medicines while you are at home."
    },
    {
      title: "Integrated treatment programmes",
      image: "./images/careimge3.jpg",
      description: "Treatment plans include consultations, clinical and laboratory examinations periodically, with follow-up of your condition with a specialist doctor without the hassle of going to hospitals."
    },
    {
      title: "Clear costs and great care",
      image: "./images/careimg4.jpg",
      description: "Medical services at an affordable price with the possibility of quick linking with various insurance companies."
    },
  ];

  const cards = [
    {
      title: "Dr. Maram Yousef",
      designation: "Subspecialty Consultant",
      description: "Vascular surgeon",
      img: img21,

    },
    {
      title: "Dr. Maram Yousef",
      designation: "Subspecialty Consultant",
      description: "Vascular surgeon",
      img: img21,
    },
    {
      title: "Dr. Maram Yousef",
      designation: "Subspecialty Consultant",
      description: "Vascular surgeon",
      img: img21,
    },
    {
      title: "Dr. Maram Yousef",
      designation: "Subspecialty Consultant",
      description: "Vascular surgeon",
      img: img21,
    },
    {
      title: "Dr. Maram Yousef",
      designation: "Subspecialty Consultant",
      description: "Vascular surgeon",
      img: img21,
    },
    {
      title: "Dr. Maram Yousef",
      designation: "Subspecialty Consultant",
      description: "Vascular surgeon",
      img: img21,
    },
    {
      title: "Dr. Maram Yousef",
      designation: "Subspecialty Consultant",
      description: "Vascular surgeon",
      img: img21,
    },
    // Add more cards as needed
  ];

  return (
    <div>
      <Navbar />
      <div className="individual-page">
        <div className="indi-meet mt-5">
          We are here to meet the needs of you and your family
        </div>
        <div className="indi-intigrated">
          An integrated hospital at your fingertips. Our services will be
          delivered at your home, in an easy, convenient, and fast way.
        </div>
      </div>
      <section className="ind-card">
        <div className="container">
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 text-center mx-5">
            {indicard1data.map((indiitem) => (
              <div className="my-4" key={indiitem.title}>
                <Link to={indiitem.path} className="text-decoration-none">
                  <div className="card get-size">
                    <img className="iv-drip-1" src={indiitem.image} alt={indiitem.title} />
                    <div className="">
                      <p className="poppins-regular zw_20 zw_text_color">
                        {indiitem.title}
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="my-5">
        <div className="container indi-connect">
          <div className="row">
            <div className="col-lg-9 col-md-9">
              <p className="m-0 poppins-regular zw_16 zw_text_color">
                We accept Bupa, Tawuniya, MEDGULF, Malath and AlRajhi
                Takaful insurance for telemedicine
              </p>
            </div>
            <div className="col-lg-3 col-md-3">
              <a
                href="/"
                className="to-link zw_title_color poppins-regular zw_16"
              >
                To link your insurance login or sign up
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="my-5">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="d-flex align-items-center">
                <div className="">
                  <img className="iv-drip-" src={img11} alt="" />
                </div>
                <div className="mx-3">
                  <p className="m-0 poppins-regular zw_16 zw_text_color">
                    Zuwarh is a licensed company by the Saudi Ministry of Health
                    with License No.************
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="indicard2">
        <div className="container">
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
            {indicard2data.map((indiitem2) => (
              <div className="my-4" key={indiitem2.title}>
                <div className="card get-size-2">
                  <img className="iv-drip-2" src={indiitem2.image} alt={indiitem2.title} />
                  <div className="">
                    <p className="poppins-bold zw_24 zw_text_color">
                      {indiitem2.title}
                    </p>
                    <p className="poppins-regular zw_18">
                      {indiitem2.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="my-5">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="how-to-join">
                <div className="my-3 poppins-semibold zw_46 how-to-order">
                  How to order from Zuwarh
                </div>

                <div className="indi-order-page ">
                  <div className="indi-how-order">
                    <img
                      className="undraw-approve-qwp-71"
                      src="/images/undraw_approve_qwp71.png"
                      alt="undraw_approve_qwp71"
                    />
                    <div className="img-sub-title poppins-medium zw_18  ">
                      Choose the service or clinic
                    </div>
                    <div className="des-width">
                      <p className="poppins-regular  zw_18 m-0">
                        Doctor visits, Lab, vaccinations, home nursing services,
                        etc
                      </p>
                    </div>
                  </div>
                  <img className="vector-1" src="/images/Vector 1-1.svg" alt="Vector" />
                  <div className="indi-how-order">
                    <img
                      className="undraw-approve-qwp-71"
                      src="/images/undraw-online.png"
                      alt="undraw-online"
                    />
                    <div className="img-sub-title poppins-medium zw_18">
                      Select location and schedule
                    </div>
                    <div className="des-width">
                      <p className="poppins-regular text-center zw_18 m-0">
                        Synchronized with your schedule from comfort of your
                        home
                      </p>
                    </div>
                  </div>
                  <img className="vector-1" src="/images/Vector 1-2.svg" alt="Vector" />
                  <div className="indi-how-order">
                    <img
                      className="undraw-approve-qwp-71"
                      src="/images/undraw-job.png"
                      alt="undraw-job"
                    />
                    <div className="img-sub-title poppins-medium zw_18">
                      Explain your problem
                    </div>
                    <div className="des-width">
                      <p className="poppins-regular text-center zw_18 m-0">
                        And get an appropriate medical diagnosis
                      </p>
                    </div>
                  </div>

                  <img className="vector-1" src="/images/Vector 1-2.svg" alt="Vector" />
                  <div className="indi-how-order">
                    <img
                      className="undraw-approve-qwp-71"
                      src="/images/undraw-job.png"
                      alt="undraw-job"
                    />
                    <div className="img-sub-title poppins-medium zw_18">
                      Find report in the application
                    </div>
                    <div className="des-width">
                      <p className="poppins-regular text-center zw_18 m-0">
                        Medical reports and prescriptions will be attached to
                        the application
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container">
        <div className="indi-licens">
          <div className="indi-licensed-doctors">
            Licensed doctors in various specialties
          </div>
          <div className="indi-licens-ellipse">
            <div className="indi-licens-ellipse-sub">
              <div className="indi-licens-ellipse-circle"></div>
            </div>
            <span className="indi-doctor896">
              896 doctors are now available for immediate consultation
            </span>
          </div>
        </div>
      </section>
      <section className="containe" style={{ margin: "0 6% 0 6%" }}>
        <div className=" row row-cols-sm-3 row-cols-md-2 row-cols-lg-3 row-cols-xl-4  my-5">
          <div className="  my-3 d-flex">
            <img className="check-uncheck" src={img19} alt="check-uncheck" />

            <div className="poppins-regular zw_18">
              Developmental pediatrician
            </div>
          </div>
          <div className="  my-3 d-flex">
            <img className="check-uncheck" src={img19} alt="check-uncheck" />

            <div className="poppins-regular zw_18">
              Family medicine physician
            </div>
          </div>
          <div className="  my-3 d-flex">
            <img className="check-uncheck" src={img19} alt="check-uncheck" />

            <div className="poppins-regular zw_18">
              Colon and rectal surgeon
            </div>
          </div>
          <div className="  my-3 d-flex">
            <img className="check-uncheck" src={img19} alt="check-uncheck" />

            <div className="poppins-regular zw_18">Obstetrician</div>
          </div>

          <div className="  my-3 d-flex">
            <img className="check-uncheck" src={img19} alt="check-uncheck" />


            <div className="poppins-regular zw_18">
              Geriatric medicine specialist
            </div>
          </div>
          <div className="  my-3 d-flex">
            <img className="check-uncheck" src={img19} alt="check-uncheck" />

            <div className="poppins-regular zw_18">Psychologist</div>
          </div>
          <div className="  my-3 d-flex">
            <img className="check-uncheck" src={img19} alt="check-uncheck" />

            <div className="poppins-regular zw_18">Neurologist</div>
          </div>
          <div className="  my-3 d-flex">
            <img className="check-uncheck" src={img19} alt="check-uncheck" />

            <div className="poppins-regular zw_18">
              Infectious disease specialist
            </div>
          </div>
        </div>
      </section>
      <section
        className="container"
        style={{ overflowX: "hidden", marginLeft: "5%", marginRight: "7%" }}
      >
        <div className="row row-cols-lg-4 row-cols-md-3">
          <div className="col">
            <div className="card-slider">
              <div
                className="slider-wrapper"
                style={{ transform: `translateX(-${currentIndex * 100}%)` }}
              >
                {cards.map(
                  (
                    card,
                    index // Ensure cards loop
                  ) => (
                    <div className=" indi-slide-card " key={index}>
                      <div className="d-flex justify-content-center">
                        {card.img && (
                          <img
                            src={card.img}
                            alt={card.title}
                            className="mx-5"
                            style={{

                              width: "136px",
                              borderRadius: "50%",
                            }}
                          />
                        )}
                      </div>
                      <div className="card-body text-center">
                        <p className="card-titl img-dr-title  poppins-bold zw_24 ">
                          {card.title}
                        </p>
                        <p className="poppins-medium zw_18 text-color-0C273C">
                          {card.designation}
                        </p>
                        <p className="poppins-regular zw_16 zw_secondary">
                          {card.description}
                        </p>
                      </div>
                      <div className=" border-0 bg-transparent mt-5">
                        <div className="d-flex justify-content-center">
                          <img className="indi-star" src={img20} alt="star" />
                          <img className="indi-star" src={img20} alt="star" />
                          <img className="indi-star" src={img20} alt="star" />
                          <img className="indi-star" src={img20} alt="star" />
                          <img className="indi-star" src={img20} alt="star" />
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="d-flex my-4">
            <button
              className="prev-btn px-5 py-3 zw_bg_gradient"
              onClick={prevSlide}
            >
              <img src="./images/rightarr.png" alt="right-arrow" />

            </button>
            <button
              className="next-btn px-5 py-3 zw_bg_gradient"
              onClick={nextSlide}
            >
              <img src="./images/leftarr.png" alt="leftarr" />
            </button>
          </div>
          <div className="my-4">
            <button className="px-5 py-3 poppins-semibold zw_bg_gradient zw_16 border-0 zw_text_fff">
              SEE ALL DOCTOR
            </button>
          </div>
        </div>
      </section>
      <section className="my-5">
        <div className="container d-flex justify-content-between ">
          <div className="book-appoi">
            <h1 className="poppins-bold px-4">
              Book an appointment from our available services
            </h1>
            <p className=" my-2 poppins-regular zw_16">
              Get immediate care for you and your family
            </p>
          </div>
          <div className="book-appoi">
            <h1 className="poppins-bold px-5">
              Request personalized health services
            </h1>
            <p className="poppins-regular zw_16 my-2">
              We will contact you to meet your specific needs
            </p>
            <Link to="/request">
              <button className="py-3 px-5 mt-3 zw_bg_gradient poppins-semibold zw_text_fff border-0" style={{ cursor: 'pointer' }}>
                {" "}
                <img className="px-4" src={img12} alt="request" />{" "}
                <span className="zw_14">Request Now</span>
              </button>
            </Link>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default CareIndividuals;
