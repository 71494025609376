export const data = [
    {
        "id": 1,
        "logo": "./images/doctor.png",
        "name": "Dr zara ",
        "designation": "physiotherapist",
        "experience": "Experience 5 years",
        "telemedicine": " Telemedicine : 15 min",
        "price": "349",
    },
    {
        "id": 2,
        "logo": "./images/doctor.png",
        "name": "Dr Mochi",
        "designation": "Cardiologist",
        "experience": "Experience 6 years",
        "telemedicine": "Telemedicine : 25 min",
        "price": "649",
    },

]