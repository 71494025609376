import React, { useState } from 'react'
// import '../../../Style/Reports.css'
import { Link } from 'react-router-dom'
import Navbar from '../../Layout/Navbar'
import Footer from '../../Layout/Footer'

function Reports() {

    const [selectedOption, setSelectedOption] = useState('');

    const handleCheckboxChange = (value) => {
        setSelectedOption(value);
    };
    return (
        <div>
            <Navbar />
            <div className="appiontments-YKE">
                <div className="auto-group-uzrn-4Ha">
                    <div className="auto-group-sqbn-mSt">

                    </div>
                    <Link to="/profile">
                        <div className="auto-group-fzyn-1Gg">
                            <img className="group-1261154072-jTa" src="/images/group-1261154072-2y2.png" alt='' />
                            <p className="poppins-medium zw_18 zw_333333" style={{ margin: '0' }}>Back</p>
                        </div>
                    </Link>
                    <div className="group-1261154802-aUC mt-5">
                        <p className="appointment-7yv poppins-medium zw_24 zw_title_color">Reports</p>


                        <div className='d-flex'>
                            <div className='d-flex'>
                                <select
                                    id="patientcode"
                                    name="patientcode"
                                    className=" zw_form_control zw_secondary poppins-regular zw_16 pe-5"
                                    style={{ borderRadius: "8px", paddingLeft: "30px" }}
                                >
                                    <option value="" >
                                        Selected patient

                                    </option>
                                    <option value="abc">
                                        abc
                                    </option>
                                    <option value="def">
                                        def
                                    </option>
                                </select>
                                {/* <i className="icon-down-arrow zw_icon_drop" ></i> */}
                            </div>
                            <img className="group-1261154799-R1W" src="/images/group-1261154799.png" alt='sda' data-bs-toggle="modal" data-bs-target="#iconBackdrop" />
                        </div>
                        {/* <img className="group-1261154799-R1W" src="/images/group-1261154799.png" alt='sda' data-bs-toggle="modal" data-bs-target="#iconBackdrop" /> */}
                        <div class="modal fade" id="iconBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" style={{ marginTop: "100px" }}>
                            <div class="modal-dialog">
                                <div class="modal-content" style={{ width: "700px" }}>
                                    <div >

                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>


                                    {/* <div className="modal-body" style={{ margin: "25px" }} >

                                        <div class="modal-header">
                                            <h5 class="modal-title" id="staticBackdropLabel">Home Visit</h5>

                                        </div>
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="staticBackdropLabel">Telemedicine</h5>

                                        </div>
                                        <div >
                                            <h5 class="modal-title" id="staticBackdropLabel" style={{ margin: "10px" }}>All</h5>

                                        </div>

                                        <div class="d-grid gap-2" style={{ marginTop: "10px", height: "30px" }}>

                                            <button class="btn btn-primary" type="button" style={{ backgroundColor: "#AF2245", borderColor: "#AF2245" }}>Save</button>
                                        </div>

                                    </div>  */}
                                    <div className="modal-body" style={{ margin: "25px" }} >


                                        <div class="modal-header">
                                            <div class="form-check" style={{ margin: "10px" }}>

                                                <label className="form-check-label" htmlFor="homeVisit">
                                                    Home Visit
                                                </label>
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="homeVisit"
                                                    value="homeVisit"
                                                    checked={selectedOption === "homeVisit"}
                                                    onChange={() => handleCheckboxChange("homeVisit")} style={{ marginLeft: "550px", marginTop: "-20px", backgroundColor: selectedOption === "homeVisit" ? "#AF2245" : "transparent", border: "none", borderColor: "none" }} />
                                            </div>
                                        </div>
                                        <div class="modal-header">
                                            <div class="form-check" style={{ margin: "10px" }}>

                                                <label className="form-check-label" htmlFor="telemedicine">
                                                    Telemedicine
                                                </label>
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="telemedicine"
                                                    value="telemedicine"
                                                    checked={selectedOption === "telemedicine"}
                                                    onChange={() => handleCheckboxChange("telemedicine")} style={{ marginLeft: "550px", marginTop: "-20px", backgroundColor: selectedOption === "telemedicine" ? "#AF2245" : "transparent", border: "none", borderColor: "none" }} />
                                            </div>
                                        </div>
                                        <div class="modal-header">
                                            <div class="form-check" style={{ margin: "10px" }}>

                                                <label className="form-check-label" htmlFor="all">
                                                    All
                                                </label>
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="all"
                                                    value="all"
                                                    checked={selectedOption === "all"}
                                                    onChange={() => handleCheckboxChange("all")} style={{ marginLeft: "550px", marginTop: "-20px", backgroundColor: selectedOption === "all" ? "#AF2245" : "transparent", border: "none", borderColor: "none" }} />
                                            </div>
                                        </div>
                                        <div class="d-grid gap-2" style={{ marginTop: "10px", height: "30px" }}>
                                            <button class="btn btn-primary" type="button" style={{ backgroundColor: "#AF2245", borderColor: "#AF2245" }}>Save</button>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="auto-group-4het-P4G">
                        <div className="group-1261154801-LEQ">
                            <div className="group-1261154793-TZv">
                                <Link to="/appointment">
                                    <div className="auto-group-mqye-CXW">
                                        <img className="group-1261154899-8RA" src="/images/group-1261154899-DQY.png" alt='' />
                                        <div className="poppins-medium zw_18 zw_text_color">Appointments</div>
                                    </div>
                                </Link>
                                <Link to="/prescription">
                                    <div className="auto-group-mqye-CXW">
                                        <img className="group-1261154899-8RA" src="/images/prescription1-5W4.png" alt='' />
                                        <div className="poppins-medium zw_18 zw_text_color">Prescription</div>
                                    </div>
                                </Link>
                                <div className="auto-group-mqye-CXW" style={{ backgroundColor: '#AF2245' }}>
                                    <img className="group-1261154899-8RA" src="/images/group-1261154896-fbi.png" alt='' />
                                    <div className="poppins-medium zw_18 zw_text_fff">Reports</div>
                                </div>

                            </div>
                        </div>
                        <div className="auto-group-pzzj-Tdn">
                            <div className="auto-group-vvxk-nvx">
                                <button className='active group-1261154912-vnG'>Reports</button>
                            </div>
                            <div className='auto-6se'>
                                <input type="text" className='search-box poppins-medium zw_18 zw_text_color' placeholder='Selected patient' />
                                <img className="vector-1vL" src="/images/vector-vFW.png" alt='' />
                                <div className="poppins-medium zw_18 nodata">No data found</div>
                            </div>
                            {/* <div className="auto-group-zydn-6se">
                <div className="group-1261154803-o3a">
                  <div className="group-1261154804-KXi">
                    <img className="vector-1vL" src="/images/vector-vFW.png" />
                    <div className="selected-patient-9me">Selected patient</div>
                  </div>
                  <div className="no-data-found-3ME">No data found</div>
                </div>
              </div> */}
                        </div>


                    </div>
                </div>
                {/* <div className="group-1000002072-LLL">
          <div className="group-1261154099-SeG">
            <img className="group-1261154093-mgY" src="./images/group-1261154093-kgk.png" />
            <img className="group-1261154040-6Tv" src="./images/group-1261154040-Xn4.png" />
          </div>
          <div className="auto-group-xqpl-qAc">
            <p className="company-aP6">Company</p>
            <div className="frame-37135-Kba">
              <p className="home-Sw6">Home</p>
              <p className="about-PrL">About</p>
              <p className="join-as-provider-9Ki">Join as provider</p>
              <p className="get-care-4xU">Get Care</p>
              <p className="join-us-1si">Join Us</p>
            </div>
          </div>
          <div className="auto-group-kh7s-kKW">
            <p className="laboratory-H4Y">Laboratory</p>
            <div className="frame-37136-RAk">
              <p className="general-test-xgU">General test</p>
              <p className="blood-test-JkL">Blood test</p>
              <p className="urine-test-f56">Urine test</p>
              <p className="dna-test-owz">DNA Test</p>
            </div>
          </div>
          <div className="auto-group-nas8-ZAU">
            <p className="contact-us-hGg">Contact Us</p>
            <p className="item-923489934721-2Zr">+923489934721</p>
            <p className="zwaarahealthcom-YHJ">Zwaara@health.com</p>
            <p className="riadh-2343-saudi-arabia-FxQ">Riadh 2343, Saudi Arabia,</p>
          </div>
        </div> */}
            </div>
            <Footer />
        </div>
    )
}

export default Reports