import React, { useState } from 'react'
import Navbar from '../Layout/Navbar'
import Footer from '../Layout/Footer'
import { useNavigate } from 'react-router-dom';

function Terms_Conditions() {
    const navigate = useNavigate();
    const [activeItem, setActiveItem] = useState(null);
    const toggleItem = (id) => {
        setActiveItem(prevActiveItem => (prevActiveItem === id ? null : id));
    };




    const TermsConditionsData = [
        {
            "id": "One",
            "questions": "Purchases",
            "answers": ` If you wish to purchase any product or service made available through the Service ("Purchase"), you may be asked to supply certain information relevant to your Purchase including, without limitation, your credit card number, the expiration date of your credit card, your billing address, and your shipping information. You represent and warrant that: (i) you have the legal right to use any credit card(s) or other payment method(s) in connection with any Purchase; and that (ii) the information you supply to us is true, correct and complete. By submitting such information, you grant us the right to provide the information to third parties for purposes of facilitating the completion of Purchases. We reserve the right to refuse or cancel your order at any time for certain reasons including but not limited to: product or service availability, errors in the description or price of the product or service, error in your order or other reasons.
- We accept payments online using Visa and MasterCard credit/debit card in SAR.
- Refunds will be done only through the Original Mode of Payment.`
        },
        {
            "id": "Two",
            "questions": "Availability, Errors and Inaccuracies",
            "answers": "We are constantly updating our offerings of products and services on the Service. The products or services available on our Service may be mis-priced, described inaccurately, or unavailable, and we may experience delays in updating information on the Service and in our advertising on other web sites. We cannot and do not guarantee the accuracy or completeness of any information, including prices, product images, specifications, availability, and services. We reserve the right to change or update information and to correct errors, inaccuracies, or omissions at any time without prior notice."
        },
        {
            "id": "Three",
            "questions": "Contests, Sweepstakes and Promotions",
            "answers": "Any contests, sweepstakes or other promotions (collectively, 'Promotions') made available through the Service may be governed by rules that are separate from these Terms. If you participate in any Promotions, please review the applicable rules as well as our Privacy Policy. If the rules for a Promotion conflict with these Terms, the Promotion rules will apply."
        },
        {
            "id": "Four",
            "questions": "Accounts",
            "answers": " When you create an account with us, you must provide us with information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on our Service. You are responsible for safeguarding the password that you use to access the Service and for any activities or actions under your password, whether your password is with our Service or a third- party service. You agree not to disclose your password to any third party. You must notify us immediately upon becoming aware of any breach of security or unauthorised use of your account. You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you without appropriate authorisation, or a name that is otherwise offensive, vulgar or obscene."
        },
        {
            "id": "Five",
            "questions": "Intellectual Property",
            "answers": "The Service and its original content, features and functionality are and will remain the exclusive property of zuwara eCommerce Company LLC and its licensors. The Service is protected by copyright, trademark, and other laws of both the Saudi Arabia and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of zuwara eCommerce Company LLC."
        },
        {
            "id": "Six",
            "questions": "Links To Other Websites",
            "answers": " Our Service may contain links to third-party web sites or services that are not owned or controlled by zuwara eCommerce Company LLC. zuwara eCommerce Company LLC has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party websites or services. You further acknowledge and agree that zuwara eCommerce Company LLC shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services. We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you visit."
        },
        {
            "id": "Seven",
            "questions": "Termination",
            "answers": ` We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms. Upon termination, your right to use the Service will immediately cease. If you wish to terminate your account, you may simply discontinue using the Service.`
        },
        {
            "id": "Eight",
            "questions": "Limitation Of Liability",
            "answers": "In no event shall zuwara eCommerce Company LLC, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damages, and even if a remedy set forth herein is found to have failed of its essential purpose."
        },
        {
            "id": "Nine",
            "questions": "Disclaimer",
            "answers": `Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE" basis. The Service is provided without warranties of any kind, whether expressed or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance. zuwara eCommerce Company LLC its subsidiaries, affiliates, and its licensors do not warrant that a) the Service will function uninterrupted, secure or available at any particular time or location; b) any errors or defects will be corrected; c) the Service is free of viruses or other harmful components; or d) the results of using the Service will meet your requirements.`
        },

    ]

    return (
        <div>
            <Navbar />

            <section className=" zw_faq" style={{ marginTop: '90px' }}>
                <div className="container">
                    <div className="faq-box-inner">
                        <span className="btn-back zw_btn_18 poppins-medium zw_black" onClick={() => navigate(-1)}>
                            <i className="icon-arrow-left"></i>
                            <span className="mob-none">Back</span>
                        </span>
                        <div className='mt-4 pt-4'>
                            <h4 className="tit zw_24 zw_text_color poppins-semibold">Terms and Conditions</h4>
                            <p className='poppins-regular zw_16 zw_text_color'>These Terms and Conditions ("Terms", "Terms and Conditions") govern your relationship with Zwaara mobile application (the "Service") operated by Zwaara -e- Commerce Company LLC ("us", "we", or "our"). Please read these Terms and Conditions carefully before using our Zwaara mobile application (the "Service"). Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service. By accessing or using the Service, you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service.</p>
                        </div>

                        <div className="accordion" id="myAccordion">
                            {TermsConditionsData.map((item) => (
                                <div className="accordion-item " key={item.id} >
                                    <h2 className="accordion-header " id={`header${item.id}`} >
                                        <button
                                            type="button"
                                            className={` accordion-button ${activeItem === item.id ? '' : 'collapsed'}  poppins-semibold zw_text_color zw_16`}
                                            onClick={() => toggleItem(item.id)}
                                            style={{ fontSize: '16px', color: '#111535' }}
                                        >
                                            {item.questions}
                                        </button>
                                    </h2>
                                    <div
                                        id={`accordion_${item.id}`}
                                        className={`accordion-collapse collapse ${activeItem === item.id ? 'show' : ''} poppins-regular zw_text_color zw_14`}
                                        data-bs-parent="#myAccordion"
                                    >
                                        <div className="accordion-body">
                                            <p className='px-4  poppins-regular zw_secondary'>{item.answers}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Terms_Conditions