export const data1 = [
    {

        "Image": "images/beautysalon.png",
        "Title": "Beauty Salon at home"
    },
    {

        "Image": "images/Home spa.png",
        "Title": "Home spa massage"
    },
    {

        "Image": "images/laser hair.png",
        "Title": "laser hair removal at home"
    },


]