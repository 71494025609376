import React, { useContext, useState } from 'react';
import '../../Style/login_popup.css';
import { Context } from '../../Context';

function AccountSetup_details() {
    const { signupFormData, updateSignupFormData } = useContext(Context);
    const [username, setUsername] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [formErrors, setFormErrors] = useState({});

    const validate = () => {
        const errors = {};

        if (!signupFormData.Email) {
            errors.Email = 'Email is required!';
        }

        const password = signupFormData.Password;
        if (!password) {
            errors.Password = 'Password is required!';
        } else {
            const hasUpperCase = /[A-Z]/.test(password);
            const hasLowerCase = /[a-z]/.test(password);
            const hasDigit = /\d/.test(password);
            const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
            const isValidLength = password.length >= 8;

            if (!hasUpperCase) {
                errors.Password = 'Password must contain at least one uppercase character!';
            }
            if (!hasLowerCase) {
                errors.Password = 'Password must contain at least one lowercase character!';
            }
            if (!hasDigit) {
                errors.Password = 'Password must contain at least one digit!';
            }
            if (!hasSpecialChar) {
                errors.Password = 'Password must contain at least one special character!';
            }
            if (!isValidLength) {
                errors.Password = 'Password must be at least 8 characters long!';
            }
        }

        if (password !== confirmPassword) {
            errors.ConfirmPassword = 'Passwords do not match!';
        }

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const isValid = validate();
        if (isValid) {
            console.log("Step 2 data", signupFormData);
            updateSignupFormData(signupFormData);

            const nextModal = new window.bootstrap.Modal(document.getElementById('accountsetup_gender'));
            const currentModal = window.bootstrap.Modal.getInstance(document.getElementById('accountsetup_details'));
            currentModal.hide();
            nextModal.show();
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        updateSignupFormData({
            ...signupFormData,
            [name]: value,
        });

        const newErrors = { ...formErrors };
        if (name === 'Email' && value) {
            delete newErrors.Email;
        }
        if (name === 'Password' && value) {
            delete newErrors.Password;
        }
        if (name === 'Password' && value === confirmPassword) {
            delete newErrors.ConfirmPassword;
        }
        setFormErrors(newErrors);
    };

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    };

    // const handleSubmit = (event) => {
    //     event.preventDefault();

    //     setFormErrors({});

    //     const errors = validate();

    //     if (Object.keys(errors).length > 0) {
    //         setFormErrors(errors);
    //     } else if (password !== confirmPassword) {
    //         setFormErrors({ confirmPassword: "Passwords do not match" });
    //     } else {
    //         const formData = {
    //             email: email,
    //             password: password
    //         };

    //         axios.post  ('https://zwarra.biztechnosys.com/api/register/step2', {
    //             formData
    //         })
    //         .then(result => {
    //             console.log(result)
    //             if (result.data==="Success" ) {
    //                 return result.json();   
    //             }
    //         })
    //         .then(data => {
    //             console.log(data);
    //             setEmail('');
    //             setUsername('');
    //             setPassword('');
    //             setConfirmPassword('');
    //         })
    //         .catch(error => {
    //             console.error('There was a problem with your fetch operation:', error);
    //         });
    //     }
    // };  
    return (
        <div className='zw_popup'>
            <div className="modal fade" id="accountsetup_details" role="dialog" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className='btn-space'>
                                <button className="sps-dialog-close regv2back" data-bs-dismiss="modal" aria-label="Close">
                                    <i className="icon-close"></i>
                                </button>
                            </div>
                            <div>
                                <h6 className='poppins-semibold zw_text_color zw_32 mb-4' style={{ textAlign: "center" }}>Account Setup</h6>
                            </div>
                            <div className="container ps-5">
                                <p className='poppins-regular zw_24 zw_9B9B9B mb-0'>
                                    Step 1 completed of 4
                                </p>
                                <div className='line_indicator_container w-100'>
                                    <div className='line-indicator-bg each_line_indicator active'></div>
                                    <div className='line-indicator-bg each_line_indicator'></div>
                                    <div className='line-indicator-bg each_line_indicator'></div>
                                    <div className='line-indicator-bg each_line_indicator'></div>
                                </div>
                                <div className="prog-grid1">
                                    <div className="prog-bar1" style={{ backgroundColor: "#DDE0E6" }}>
                                        <div className="prog-bar-per" style={{ width: "25%" }}></div>
                                    </div>
                                </div>
                            </div>
                            <div className='container' style={{ backgroundColor: "#f7e9ec", padding: "20px", marginTop: "20px" }}>
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group zw_form_group mb-0">
                                        <input type='text' className='px-2 py-3 zw_form_control zw_secondary poppins-regular zw_16' placeholder='Email' style={{ width: "100%" }} name="Email" value={signupFormData.Email} onChange={handleChange}></input>
                                    </div>
                                    {formErrors.Email && <span className="sa-error-message" style={{ 'color': "red" }}>{formErrors.Email}</span>}
                                    <div className="form-group zw_form_group mb-0 mt-5">
                                        <input type='text' className='px-2 py-3 zw_form_control zw_secondary poppins-regular zw_16' placeholder='Username' style={{ width: "100%" }} value={username} onChange={handleUsernameChange}></input>
                                    </div>
                                    <div className="form-group zw_form_group mb-0 mt-5">
                                        <input type='password' className='px-2 py-3 zw_form_control zw_secondary poppins-regular zw_16' placeholder='Create Password' style={{ width: "100%" }} name="Password" value={signupFormData.Password} onChange={handleChange}></input>
                                    </div>
                                    {formErrors.Password && <span className="sa-error-message" style={{ 'color': "red" }}>{formErrors.Password}</span>}
                                    <div className="form-group zw_form_group mb-0 mt-5">
                                        <input type='password' className='px-2 py-3 zw_form_control zw_secondary poppins-regular zw_16' placeholder='Confirm Password' style={{ width: "100%" }} value={confirmPassword} onChange={handleConfirmPasswordChange}></input>
                                    </div>
                                    {formErrors.ConfirmPassword && <span className="sa-error-message" style={{ 'color': "red" }}>{formErrors.ConfirmPassword}</span>}
                                    <button type='submit' className='zw_text_fff mt-5 poppins-medium zw_24 zw_bg_gradient zw_btn_18 zw_border_none p-2' style={{ width: "80%", margin: "0 10%", borderRadius: '6px' }}>
                                        Next
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* ====================================  done  ========================== */}

        </div>
    );
}

export default AccountSetup_details;
