import React from 'react'
import '../.././Style/AboutZwaara.css'
import { Link } from 'react-router-dom';
function Container() {
    return (
        <div>
            <div className="about-zuwarh">
                <div className="frame-1261154268">
                    <div className='nav-sec-content'>
                        <div className="group-1261155571">
                            <span className="zuwarh primary-linear-gradient-text poppins-semibold zw_18"> Zuwara</span>
                            <img src="/images/Line 7.png" style={{ height: '5px', marginBottom: '5px' }} alt="Line 7" />
                        </div>
                        <div className="primary-linear-gradient-text poppins-semibold zw_46">
                            Primary Care Services And Telemedicine Consultations Providers
                        </div>
                        <div className="lorem-ipsum-dolor poppins-regular zw_16 zw_text_color">
                            Lorem ipsum dolor sit amet consectetur. Risus nec ullamcorper purus egestas. Fusce donec viverra tortor,
                        </div>
                        <Link to="/">
                        <div className="group-1261155549">
                            <span className="poppins-medium zw_18 zw_text_fff">
                                Read more
                            </span>
                        </div>
                        </Link>
                    </div>
                    <div className='nav-sec-img'>
                        <div className='nav-bar-img-sec'>
                            <div className="img-sec-39722">
                                <img className='img-width' src="/images/Rectangle 39722.png" alt='images/Rectangle 39722'/>
                            </div>
                            <div className="img-sec-39723 img-width">
                                <img className='img-width' src="/images/Rectangle 39723.png" alt='Rectangle 39723'/>
                            </div>
                            <div className="img-sec-39725 img-width">
                                <img className='img-width' src="/images/Rectangle 39725.png" alt='Rectangle 39725'/>
                            </div>
                        </div>
                        <div className='nav-bar-img-sec'>
                            <div className="img-sec-39719 img-width">
                                <img className='img-width' src="/images/Rectangle 39719.png" alt='Rectangle 39719'/>
                            </div>
                            <div className="img-sec-39720 img-width">
                                <img className='img-width' src="/images/Rectangle 39720.png"alt='Rectangle 39720' />
                            </div>
                            <div className="img-sec-39724 img-width">
                                <img className='img-width' src="/images/Rectangle 39724.png" alt='Rectangle 39724' />
                            </div>
                        </div>
                        <div className='nav-bar-img-sec-1'>
                            <div className="img-sec-39721 img-width">
                                <img className='img-width' src="/images/Rectangle 39718.png" alt='Rectangle 39718'/>
                            </div>
                            <div className="img-sec-39721 img-width">
                                <img className='img-width' src="/images/Rectangle 39721.png"alt='Rectangle 39721' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container-content-section'>
                    <div className="group-1261155596" >
                        <div className="group-513385">
                            <div className="poppins-semibold zw_46 zw_title_color">
                                Our Vision
                            </div>
                            <div className="our-card-text poppins-regular zw_16 zw_secondary">
                                To be the best provider of integrated and reliable medical services, Providing solutions that raise the quality of healthy life for the individual and society.
                            </div>
                        </div>
                        <div>
                            <img className="imagination-cuate-1" src="/images/Imagination-cuate 1.png" alt='Imagination-cuate 1' />
                        </div>
                    </div>
                    <div className="group-1261155596" >
                        <div>
                            <img className="imagination-cuate-1" src="/images/Business mission-amico 1.png" alt='Business mission-amico 1' />
                        </div>
                        <div className="group-513385">
                            <div className="poppins-semibold zw_46 zw_title_color">
                                Our Mission
                            </div>
                            <div className="our-card-text poppins-regular zw_16 zw_secondary">
                                Providing high quality home medical care services in a wide geographical distribution and an effective medical digital solutions for medical consultations and a unified EMR.
                            </div>
                        </div>
                    </div>
                    <div className="group-1261155607" >
                        <div className="group-1261154282">
                            <div className="group-1261154269">
                                <div className="poppins-semibold zw_46 zw_title_color">
                                    What We Serve
                                </div>
                            </div>
                            <span className="oplossing-voor-gezin poppins-semibold zw_9B9B9B zw_16">
                                Oplossing voor gezinnen die extra zorg nodig hebben Oplossing voor gezinnen die extra zorg nodig hebben Oplossing voor gezinnen die extra zorg nodig hebben
                            </span>
                        </div>
                    </div>
                    <div className="group-1261155606" >
                        <div className="container-12">
                            <div className="group-1261155601">
                                <div>
                                    <div className="link-extra-security">
                                        Patients and their families
                                    </div>
                                    <div className="provide-awide-rang">
                                        provide a wide rang of senice from video medical consultations to home delivery of medicines. As well as follow-up of their health, periodic vaccinatione, physiotherapy
                                    </div>
                                    <Link to="/">
                                    <div className="see-services">
                                        See services
                                    </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="group-1261155599" >
                                <div>
                                    <div className="link-extra-security">
                                        Companies and employees
                                    </div>
                                    <div className="provide-awide-rang">
                                        We support you in apply occupational safety and health to enhaines loyalty of all of your employees
                                    </div>
                                    <Link to="/">
                                    <div className="see-services">
                                        See services
                                    </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="group-1261155597">
                            <div className="group-1261155594">
                                <div className="link-extra-security">
                                    Doctors and specialists
                                </div>
                                <div className="provide-awide-rang">
                                    Would you like to provide your medical advice through Sanar
                                </div>
                                <Link to="/">
                                <div className="see-services">
                                    See services
                                </div>
                                </Link>
                            </div>
                        </div>

                        <div className="container-15">
                            <div className="group-1261155603">
                                <div className="link-extra-security">
                                    Insurance companies
                                </div>
                                <div className="provide-awide-rang">
                                    Do you want to provide distinguished medical services to members and subscribers
                                </div>
                                <Link to="/">
                                <div className="see-services">
                                    See services
                                </div>
                                </Link>
                            </div>
                            <div className="group-1261155605">
                                <div className="link-extra-security">
                                    Hospitals and healthcare centers
                                </div>
                                <div className="provide-awide-rang">
                                    Would you like to provide your medical services through Sanar?
                                </div>
                                <Link to="/">
                                <div className="see-services">
                                    See services
                                </div>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="why-zuwarh poppins-semibold zw_46 zw_title_color"> Why Zuwara </div>
                    <div className="group-1261155615 px-2">
                        <div className="frame-1261155614">
                            <div className="group-512508">
                                <div className="group-5125081">
                                    <img className="rectangle-397384" src="/images/Rectangle 39738.png" alt='Rectangle 39738' />
                                    <img className="vector-1098" src="/images/Vector-tik.png" alt='Vector-tik' />
                                </div>
                                <div className="poppins-medium zw_16 zw_text_color">
                                    Integrated services in variety of medical fields
                                </div>
                            </div>
                            <div className="group-512508">
                                <div className="group-5125081">
                                    <img className="rectangle-397384" src="/images/Rectangle 39738.png" alt='Rectangle 39738' />
                                    <img className="vector-1098" src="/images/Vector-tik.png" alt='Vector-tik' />
                                </div>
                                <div className="poppins-medium zw_16 zw_text_color">
                                    Freedom to choose the place and tithe 24/7
                                </div>
                            </div>
                            <div className="group-512508">
                                <div className="group-5125081">
                                    <img className="rectangle-397384" src="/images/Rectangle 39738.png" alt='Rectangle 39738' />
                                    <img className="vector-1098" src="/images/Vector-tik.png" alt='Vector-tik' />
                                </div>
                                <div className="poppins-medium zw_16 zw_text_color">
                                    Wide geographical coverage 12+ cities
                                </div>
                            </div>
                            <div className="group-512508">
                                <div className="group-5125081">
                                    <img className="rectangle-397384" src="/images/Rectangle 39738.png" alt='' />
                                    <img className="vector-1098" src="/images/Vector-tik.png" alt='Vector-tik' />
                                </div>
                                <div className="poppins-medium zw_16 zw_text_color">
                                    An elite of consultants and specialists
                                </div>
                            </div>
                            <div className="group-512508">
                                <div className="group-5125081">
                                    <img className="rectangle-397384" src="/images/Rectangle 39738.png" alt='' />
                                    <img className="vector-1098" src="/images/Vector-tik.png" alt='Vector-tik' />
                                </div>
                                <div className="poppins-medium zw_16 zw_text_color">
                                    24+ F-clinics
                                </div>
                            </div>
                            <div className="group-512508">
                                <div className="group-5125081">
                                    <img className="rectangle-397384" src="/images/Rectangle 39738.png" alt='' />
                                    <img className="vector-1098" src="/images/Vector-tik.png" alt='Vector-tik' />
                                </div>
                                <div className="poppins-medium zw_16 zw_text_color">
                                    unified EMR
                                </div>
                            </div>
                            <div className="group-512508">
                                <div className="group-5125081">
                                    <img className="rectangle-397384" src="/images/Rectangle 39738.png" alt='' />
                                    <img className="vector-1098" src="/images/Vector-tik.png" alt='Vector-tik' />
                                </div>
                                <div className="poppins-medium zw_16 zw_text_color">
                                    Multiple &amp; secure payment methods
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <img className="group-1261155614" src="/images/whyzwara.png" alt='whyzwara' />
                        </div>
                    </div>

                    <div className="frame-1261154267">
                        <div className="container-21">
                            <div className="join-us-and-get-benefits-from-zawaara-now poppins-semibold zw_34 zw_title_color">
                                Join Us And Get Benefits From Zuwara Now
                            </div>
                            <p className='poppins-medium zw_18 zw_black'>To provide your medical services and enjoy innovative benefits</p>
                            <div className="container-18">
                                <div className="container-11">
                                    <div className="service-svgrepo-com-1">
                                        <img className="container-1" src="/images/service-svgrepo-com 1.png" alt='service-svgrepo-com 1' />
                                    </div>
                                    <Link to="/forindividuals">
                                    <div className="poppins-semibold zw_16 zw_title_color">
                                        Service Provider
                                    </div>
                                    </Link>
                                </div>
                                <Link to="/forbussiness">
                                <div className="container-16">
                                    <img className="service-svgrepo-com-1 container-1" src="/images/hand-heart-svgrepo-com 1.png" alt='hand-heart-svgrepo-com 1' />
                                    <div className="poppins-semibold zw_16 zw_title_color"> Get Care </div>
                                </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Container