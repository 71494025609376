import React, { useState, useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import { data1 } from "../../Faq/ServiceList";
import { data } from "../../../ClinicData.js";
import Geocoder from "../../Layout/Geocoder";
import { useValue } from "../../MapContext/MapContextProvider";
import "mapbox-gl/dist/mapbox-gl.css";
import { Context } from "../../../Context.js";
import ReactMapGL, { GeolocateControl, Marker } from "react-map-gl";
import ClinicCardItem from "../VirtualConsultation/ClinicCardItem.js";

function Service() {
  const { updateAppointmentData } = useContext(Context);
  const { t } = useTranslation();
  const [setZoneData] = useState([]);
  const [show, setShow] = useState(false);
  const [showw, setShoww] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedService, setSelectedService] = useState(null);
  const [servicesData, setServicesData] = useState([]);
  const [redirectTo, setRedirectTo] = useState("");
  const [locationName, setLocationName] = useState(""); // Add locationName state

  const openModel = (serviceName) => {
    console.log("Selected service:", serviceName);
    if (serviceName === "Virtual consultations" || serviceName === "Corporate wellness") {
      redirectToServicePage(serviceName);
    } else {
      setRedirectTo(serviceName);
      setShow(true);
      console.log("Updating context with service name:", serviceName);
      updateAppointmentData({ Servicename: serviceName });
      sessionStorage.setItem("selectedService", serviceName);
    }
  };
  const fetchZoneData = async (latitude, longitude) => {
    try {
      const response = await fetch(
        `https://zwarra.biztechnosys.com/api/fetchZoneData/${latitude}/${longitude}`,
        {
          headers: {
            "Content-Type": "application/json",
            Cookie: "zwarra_session=8svaEnKXoPHya4NjfgtmI4XABhWqWjVpkmz53q2L",
          },
        }
      );
      const data = await response.json();
      setZoneData(data); // Set the fetched zone data to state
      console.log("Zone Data:", data); // Log the zone data
    } catch (error) {
      console.error("Error fetching zone data:", error);
    }
  };
  const showLocation = (loc) => {
    setLocationName(loc);
    updateAppointmentData({ Address: loc });
    const latitude = 45.07187238118124;
    const longitude = 26.286879877969852;
    fetchZoneData(latitude, longitude);
  };
  const useCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { longitude, latitude } = position.coords;
        if (mapRef.current) {
          mapRef.current.flyTo({
            center: [longitude, latitude],
            zoom: 14,
          });
        }
        dispatch({
          type: "UPDATE_LOCATION",
          payload: { lng: longitude, lat: latitude },
        });
        fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=pk.eyJ1IjoiYWJkdWxyYTdtYW4iLCJhIjoiY2x0bzlvNTEwMDVoZTJrbWg4bHRxeXRwciJ9._WDrK6r6rayfB4WnardOwA`)
          .then(response => response.json())
          .then(data => {
            setLocationName(data.features[0]?.place_name || "Unknown location");
          });
      },
      (error) => {
        console.error("Error retrieving location:", error);
      },
      { enableHighAccuracy: true }
    );
  };

  const openVertualModel = () => {
    setShoww(true);
  };

  const handleClose = () => {
    setShow(false);
    setShoww(false);
  };

  useEffect(() => {
    const savedService = sessionStorage.getItem("selectedService");
    if (savedService) {
      setSelectedService(savedService);
    }
    fetch("https://zwarra.biztechnosys.com/api/ourservices")
      .then((response) => response.json())
      .then((data) => {
        setServicesData(data);
      });

    // Set default location
    fetch("https://ipapi.co/json")
      .then((response) => response.json())
      .then((data) => {
        if (mapRef.current) {
          mapRef.current.flyTo({
            center: [data.longitude, data.latitude],
            zoom: 14,
          });
        }
        dispatch({
          type: "UPDATE_LOCATION",
          payload: { lng: data.longitude, lat: data.latitude },
        });
        setLocationName(`${data.city}, ${data.region}, ${data.country_name}`);
      });
  }, []);


  const redirectToServicePage = (enname) => {
    let newPath;
    switch (enname) {
      case "Vaccination":
        newPath = "/vaccination";
        break;
      case "Nurse visit":
        newPath = "/nursevisit";
        break;
      case "Home visit Doctor":
        newPath = "/Doctorvisit";
        break;
      case "Vitamin IV Drips":
        newPath = "/vitamin";
        break;
      case "Caregiver":
        newPath = "/caregiver";
        break;
      case "Laboratory":
        newPath = "/laboratory";
        break;
      case "Physiotherapist":
        newPath = "/physiotherapist";
        break;
      case "Radiology":
        newPath = "/radiology";
        break;
      case "Corporate wellness":
        newPath = "/corporate";
        break;
      case "Iqama":
        newPath = "/iqama";
        break;
      case "Virtual Consultation":
        setShoww(true);
        return;
      case "Seasonal flu":
        newPath = "/seasonalflu";
        break;
      case "Iqama":
        newPath = "/iqama";
        break;
      default:
        newPath = "/not-found";
        break;
    }
    window.location.href = newPath;
  };

  const {
    state: {
      location: { lng, lat },
    },
    dispatch,
  } = useValue();

  const mapRef = useRef();

  const handleConfirmLocation = (loc) => {
    updateAppointmentData({ Address: loc });
    if (loc) redirectToServicePage(redirectTo);
  };

  const handleGeocoderResult = (result) => {
    const { center, place_name } = result;
    const [longitude, latitude] = center;
    if (mapRef.current) {
      mapRef.current.flyTo({
        center: [longitude, latitude],
        zoom: 14,
      });
    }
    dispatch({
      type: "UPDATE_LOCATION",
      payload: { lng: longitude, lat: latitude },
    });
    setLocationName(place_name);
  };

  useEffect(() => {
    if (show) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { longitude, latitude } = position.coords;
          if (mapRef.current) {
            mapRef.current.flyTo({
              center: [longitude, latitude],
              zoom: 14,
            });
          }
          dispatch({
            type: "UPDATE_LOCATION",
            payload: { lng: longitude, lat: latitude },
          });
          fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=pk.eyJ1IjoiYWJkdWxyYTdtYW4iLCJhIjoiY2x0bzlvNTEwMDVoZTJrbWg4bHRxeXRwciJ9._WDrK6r6rayfB4WnardOwA`)
            .then(response => response.json())
            .then(data => {
              setLocationName(data.features[0]?.place_name || "Unknown location");
            });
        },
        (error) => {
          console.error("Error retrieving location:", error);
        },
        { enableHighAccuracy: true }
      );
    }
  }, [show, dispatch]);

  return (
    <>
      <div className="sa-services">
        <h1 className="poppins-semibold zw_title_color zw_34">Our Service</h1>
        <h2 className="poppins-semibold zwaara_h2 zw_title_color mt-5">Medical</h2>
        <div className="sa-pos-rel">
          <ul className="z-services-list m-auto">
            {servicesData.map((item) => (
              <li
                className="card border-0 text-center"
                key={item.id}
                id={item.id}
                onClick={() => openModel(item.Enname)}
              >
                <img
                  src={`https://zwarra.biztechnosys.com/${item.Logo}`}
                  alt="images"
                  title={item.Enname}
                />
                <span className="poppins-medium zw_24 zw_text_color py-4">
                  {t(`${item.Enname}`)}
                </span>
              </li>
            ))}
          </ul>
        </div>
        <h2 className="poppins-semibold zwaara_h2 zw_title_color mt-5">Non-Medical</h2>
        <div className="sa-pos-rel" data-bs-toggle="modal" data-bs-target="#nonMedicalBackdrop">
          <ul className="z-services-list m-auto">
            {data1.map((item, index) => (
              <li className="card border-0 text-center" key={index}>
                <img src={item.Image} alt={item.altText} />
                <span className="poppins-medium zw_24 zw_text_color">{item.Title}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="modal fade" id="nonMedicalBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content" data-bs-dismiss="modal" aria-label="Close" style={{ marginTop: "150px" }}>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            <div className="modal-body">
              <img src="./images/comingsoon.jpg" alt="comingsoon" style={{ width: "450px" }} />
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} animation={false} locName={showLocation}>
        <div className="sps-dialog sps-dialog-full zw_select_location">
          <div className="sps-dialog-body sps-dialog-overflow-unset sps-map">
            <button className="sps-dialog-close" onClick={handleClose}>
              <i className="icon-close"></i>
            </button>
            <div className="sps-loc-map">
              <div id="spsmapbox" className="sps-mapboxgl-map">
                <ReactMapGL
                  ref={mapRef}
                  initialViewState={{
                    longitude: lng,
                    latitude: lat,
                    zoom: 5,
                  }}
                  mapStyle="mapbox://styles/mapbox/streets-v9"
                  mapboxAccessToken="pk.eyJ1IjoiYWJkdWxyYTdtYW4iLCJhIjoiY2x0bzlvNTEwMDVoZTJrbWg4bHRxeXRwciJ9._WDrK6r6rayfB4WnardOwA"
                >
                  <Marker
                    latitude={lat}
                    longitude={lng}
                    draggable
                    onDragEnd={(e) =>
                      dispatch({
                        type: "UPDATE_LOCATION",
                        payload: { lng: e.lngLat.lng, lat: e.lngLat.lat },
                      })
                    }
                  />
                  <GeolocateControl
                    position="top-left"
                    trackUserLocation
                    onGeolocate={(e) =>
                      dispatch({
                        type: "UPDATE_LOCATION",
                        payload: {
                          lng: e.coords.longitude,
                          lat: e.coords.latitude,
                        },
                      })
                    }
                  />
                  <Geocoder onResult={handleGeocoderResult} />
                </ReactMapGL>
              </div>
            </div>

            <footer className="sps-loc-footer zw_loc_footer">
              <div className="row">
                <div className="col-md-6 sps-d-f-sbetween">
                  <h5 className="sps-sprite sps-loc-map-ico sps-loc-type poppins-semibold zw_16">
                    Visit Location
                  </h5>
                </div>
                <div className="col-md-6" style={{ textAlign: "end" }}>
                  <h5
                    className="poppins-semibold zw_16 text-right"
                    style={{ margin: 0, cursor: "pointer" }}
                    onClick={useCurrentLocation}
                  >
                    Use My Current Location
                  </h5>
                </div>
              </div>
              <div className="row zw_loc_icon">
                <div className="col-md-12 sps-mt10">
                  <div className="sps-lcn-card">
                    <div
                      className="sps-d-f-sbetween"
                      style={{ justifyContent: "flex-start", gap: "10px" }}
                    >
                      <h5 style={{ margin: 0 }}>
                        <i
                          className="icon-location2 sps-mr5 zw_title_color"
                        ></i>
                      </h5>
                      <h4 style={{ margin: 0 }}>{locationName}</h4>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-md-6 sps-ftr-res-btn poppins-medium zw_btn_18">
                  <Link className="w-100" to="/addresslist" onClick={handleClose}>
                    <button
                      type="submit"
                      className="btn  sps-btn-view zw_btn_view  "
                    >

                      <span className="zw_text_fff poppins-regular zw_14">View Saved Address</span>

                    </button>
                  </Link>
                </div>

                <div className="col-md-6 sps-ftr-res-btn poppins-medium zw_btn_18">
                  <Link className="w-100" onClick={() => handleConfirmLocation(locationName)}>
                    <button
                      id="locbtn"
                      data-bs-dismiss="modal"
                      className="btn  sps-btn-view zw_btn_view "
                      type="submit"
                    >
                      <span className="zw_text_fff poppins-regular zw_14">
                        Confirm Location
                      </span>
                    </button>
                  </Link>
                </div>
              </div>

            </footer>
          </div>
        </div>
      </Modal>
      <Modal show={showw} onHide={handleClose} className="vertualConsultationn">
        <Modal.Header closeButton>
          {/* <Modal.Title>{selectedService}</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div style={{ padding: "1rem 2rem" }}>
            <div className="d-flex flex-column flex-md-row my-5">
              <div className="poppins-regular zw_18 zw_text_color mb-3 mb-md-0" style={{ width: "24%" }}>
                Step 1 of 4
              </div>
              <div className="line_indicator_container">
                <div className="each_line_indicator active"></div>
                <div className="each_line_indicator each_line_indicator-bg"></div>
                <div className="each_line_indicator each_line_indicator-bg"></div>
                <div className="each_line_indicator each_line_indicator-bg"></div>
              </div>
            </div>
            <div className="d-flex flex-column flex-md-row">
              <div className="poppins-semibold zw_32 zw_text_color mb-3 mb-md-0" style={{ width: "44%" }}>
                E-Clinics
              </div>
              <div className="row w-100">
                <div className="col-md-12">
                  <div className="">
                    <i className="icon-search z-form-icon"></i>
                    <input
                      onChange={(e) => setSearch(e.target.value)}
                      autoComplete="off"
                      name="speciality"
                      type="text"
                      placeholder="Search Speciality"
                      className="z-form-control-sm poppins-regular zw_16"
                      style={{ width: "100%", marginLeft: "0" }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4" style={{ overflowY: "auto", maxHeight: "80vh" }}>
              <ul className="z-clinic-card-listt row row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-2">
                {data
                  .filter((item) => {
                    return search.toLowerCase() === "" ? item : item.title.toLowerCase().includes(search);
                  })
                  .map((item) => (
                    <li key={item.id} className="my-4 mx-3">
                      <Link to={`/Eclinics`}>
                        <ClinicCardItem
                          title={item.title}
                          image={item.image}
                          name={item.name}
                        />
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Service;
