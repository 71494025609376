import React, { useState } from 'react'
import '../../../Style/Home.css'
import ClinicCardItem from './ClinicCardItem'
import { data } from '../../../ClinicData.js'
import { Link } from 'react-router-dom';

function VirtualConsultationsPopup() {
    const [search, setSearch] = useState('');

    return (
        <>
            <div >
                <div >
                    <div style={{ margintop: "10px", marginLeft: "100px", marginRight: "100px" }} >
                        <div style={{ padding: '9rem 9rem 0 9rem' }}>
                            <div className='d-flex mb-3'>
                                <div className='poppins-regular zw_18 zw_text_color'>Step 1 of 4</div>
                                <div className='line_indicator_container'>
                                    <div className='each_line_indicator active'></div>
                                    <div className='each_line_indicator active'></div>
                                    <div className='each_line_indicator active'></div>
                                    <div className='each_line_indicator active'></div>
                                </div>
                            </div>
                            <div className='d-flex'>
                                <div className='poppins-semibold zw_32 zw_text_color'>E-Clinics</div>
                                <div className='row'>
                                    <div className="col-md-12">
                                        <div className="z-mob-none">
                                            <i className="icon-search z-form-icon"></i>
                                            <input onChange={(e) => setSearch(e.target.value)} autoComplete="off" name="speciality" type="text" placeholder="Search Speciality" className="form-control z-form-control-sm" style={{ width: '51rem', marginLeft: '23rem' }} />
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>


                        <div style={{ scrollBehavior: "auto" }}>
                            <ul className='z-clinic-card-list'>
                                {data.filter((item) => {
                                    return search.toLowerCase() === '' ? item : item.title.toLowerCase().includes(search);
                                }).map((item) => (
                                    <li key={item.id}>
                                        {/* <Link to={`/browser/telemedicine/deptid=${item.id}&pId=ST11&stid=ST1`}> */}
                                        <Link to={`/Selectlabs`}>
                                            <ClinicCardItem
                                                title={item.title}
                                                image={item.image}
                                                name={item.name}
                                            />
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>


                        {/* <div className='box-card-container'>
                        <div className='doctor-card-box'>
                            <div className='doctor-card-box-img'>
                                <img src="./images/virtual-img1.png" alt="" />
                            </div>
                            <div className='doctor-card-box-text'></div>
                        </div>
                        <div></div>
                    </div> */}

                    </div>
                </div>
            </div>
        </>
    )
}

export default VirtualConsultationsPopup
