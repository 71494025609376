import React, { useState } from 'react';
import Navbar from '../Layout/Navbar';
import Footer from '../Layout/Footer';
import '../../Style/Caregiver.css';
import { Link } from 'react-router-dom';
import { Caregiverdata } from '../Home/HomeServices/Dataforcaregiver';
import '../../Style/Corporatenew.css';

// import care1 from '../../assets/img/care_1.png';
// import care2 from '../../assets/img/care_2.png';
// import care3 from '../../assets/img/care_3.png';
// import care4 from '../../assets/img/care_4.png';

// const images = {
//     care1,
//     care2,
//     care3,
//     care4
// };

function Caregiver() {
    const [searchQuery, setSearchQuery] = useState('');
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredData = Caregiverdata.filter(item =>
        item.title.toLowerCase().includes(searchQuery.toLowerCase())
    );
    return (
        <div>
            <Navbar />
            <div className="nursing-task-hRe">
                <div className="group-1261155501-1WC">
                    <div className='d-flex gap-3 align-items-center'>
                        <Link to="/">
                        <div className='d-flex gap-3'>
                            <img src="./images/Group 1261154072.png" alt="" />
                            <p className='poppins-regular zw_16 zw_black mb-0 me-5'>Back</p>
                        </div>
                        </Link>
                        <div className='each_line_indicator active'></div>
                        <div className='each_line_indicator '></div>
                        <div className='each_line_indicator '></div>
                        <div className='each_line_indicator '></div>
                    </div>
                    {/* <h1>Caregiver</h1> */}
                </div>

            </div>


            <div className=" sa-content sa-top-space60 mx-5 ">
                {/* <Link className="Home-link" to="/">
                    <span className="sa-btn-back">
                        <i className="icon-arrow-left"></i>
                        <span className="back-text">Back</span>
                    </span>
                </Link> */}
                {/* <div className="sa-mob-header">
                    <h5 className="sa-mob-block">Caregiver</h5>
                    <div className="sa-prog-grid">
                        <p>
                            <span>Step 1</span> of 3
                        </p>
                        <div className="sa-prog-bar">
                            <div className="sa-prog-bar-per" style={{ width: "33.3333%" }}></div>
                        </div>
                    </div>
                </div> */}
                {/* <div> */}
                <h5 className="poppins-semibold zw_30 zw_text_color my-5">How we are working</h5>
                <div className='caregiver-working-card'>
                    <p className='poppins-regular zw_secondary zw_16'>
                        Our caregivers have undergone professional training to handle the task responsibly. They are also certified by the Ministry of Health, Saudi Arabia. They are experienced to aid the patient with activities of daily living such as personal care and hygiene, bathing, dressing and other defined set of duties and responsibilities according to the needs & type of service.
                    </p>
                </div>

                {/* </div> */}
                <h5 className="poppins-semibold zw_30 zw_text_color mt-5 mb-4">Select Caregiver Task</h5>
                <div className="row ">
                    {filteredData.map(task => (
                        <div className="col care-box-container mb-4 me-4 p-0" key={task.id}  >
                            <Link className="caregiver-link" to={task.link} >
                                {/* <div className="card" style={{ width: "500rem", padding: "0px", height: "122rem", marginBottom: "10px" }}> */}
                                <div className='card-container-width'>
                                    <div className="custom-container">
                                        <div >
                                            {/* <img className="custom-img-cover" src={images[task.image.split('.')[0]]} alt={task.title} /> */}
                                            <img className='custom-img-cover' src={task.images} alt={task.title}/>
                                        </div>
                                        <div className="ps-4 caregiver-duration">
                                            <h6 className="poppins-bold zw_16 zw_title_color">{task.duration}</h6>
                                            <small className="poppins-bold zw_16 zw_title_color">{task.hours}</small>
                                        </div>
                                    </div>
                                    <div className="card-body p-3 ms-4">
                                        <h5 className="poppins-bold zw_24 zw_text_color my-4" >{task.title}</h5>
                                        <p className="poppins-regular zw_16 zw_secondary text-align-center">{task.description}</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </div><br />
            <Footer />
        </div>
    );
}

export default Caregiver;
