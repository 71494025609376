import React, { useState, useEffect, useContext } from 'react';
import { Context } from '../../../Context'; // Import the Context

function HomeBannerSlider() {
    const { isAuthenticated } = useContext(Context); // Get the isAuthenticated state from context
    const [bannerData, setBannerData] = useState([]);

    useEffect(() => {
        fetch('https://zwarra.biztechnosys.com/api/header-banners')
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                setBannerData(data);
            });
    }, []);

    const handleLoginClick = () => {
        if (isAuthenticated) {
            alert('You are already logged in'); // Show alert if already logged in
        }
        // If not authenticated, the modal will open automatically due to data-bs attributes
    };

    return (
        <div className="container zw_home_slider">
            <div id="myCarousel" className="carousel slide slider_pt" data-bs-ride="carousel">
                <div className="carousel-inner">
                    {bannerData.map((item, index) => (
                        <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`} style={{ height: "55rem" }}>
                            <img src="images/banner.jpg" width="100%" height="100%" className="banner" style={{ height: "54rem" }} alt="Zawaara" />

                            <div className="container">
                                <div className="banner-left">
                                    <h1>{item.title}</h1>
                                    <p style={{ fontFamily: "Poppins, 'Source Sans Pro'" }}>{item.description}</p>
                                    <button 
                                        className='zw_title_color' 
                                        data-bs-toggle={!isAuthenticated ? "modal" : undefined} 
                                        data-bs-target={!isAuthenticated ? "#Loginmodal" : undefined} 
                                        onClick={handleLoginClick}
                                        style={{ width: "200px", height: "50px", fontSize: "22px", marginTop: "60px", fontFamily: "Poppins, 'Source Sans Pro'", border: "none", borderRadius: "5px" }}>
                                        Login
                                    </button>
                                </div>
                                <div className="banner-right"><img src="./images/bannergirl.png" width="100%" height="100%" alt="Zawaara" />
                                </div>
                            </div>

                        </div>
                    ))}
                </div>

                <div className="carousel-indicators">
                    {bannerData.map((_, index) => (
                        <button
                            key={index}
                            type="button"
                            data-bs-target="#myCarousel"
                            data-bs-slide-to={index}
                            className={index === 0 ? 'active' : ''}
                            aria-current={index === 0 ? 'true' : 'false'}
                            aria-label={`Slide ${index + 1}`}
                        ></button>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default HomeBannerSlider;
