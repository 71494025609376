import React, { useState,useContext } from 'react';
import '../../../Style/Personal.css';
import { Link } from 'react-router-dom';
import Footer from '../../Layout/Footer';
import Navbar from '../../Layout/Navbar';
import { Context } from '../../../Context';

function Personal() {
  const {username} = useContext(Context);
  const [formData, setFormData] = useState({
    id: '',
    firstname: '',
    lastname: '',
    phone: '',
    relationship: '',
    dob: '',
    gender: '',
    country: '',
    age: '',
    nationalId: '',
  });

  const [errors, setErrors] = useState({});
  const [apiResponse, setApiResponse] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validate = () => {
    let errors = {};

    // if (!formData.id) errors.id = "ID is required.";
    if (!formData.firstname) errors.firstname = "First Name is required.";
    if (!formData.lastname) errors.lastname = "Last Name is required.";
    if (!formData.phone) {
      errors.phone = "Phone Number is required.";
    } else if (!/^\d+$/.test(formData.phone)) {
      errors.phone = "Phone Number must be numeric.";
    }
    if (!formData.relationship) errors.relationship = "Relationship is required.";
    if (!formData.dob) errors.dob = "Date of Birth is required.";
    if (!formData.gender) errors.gender = "Gender is required.";
    if (!formData.country) errors.country = "Country is required.";
    if (!formData.age) errors.age = "Age is required.";
    if (!formData.nationalId) errors.nationalId = "National ID is required.";

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const updatePatientRecord = async (data) => {
    const apiUrl = 'https://zwarra.biztechnosys.com/api/updatepatient/1';

    try {
      const response = await fetch(apiUrl, {
        method: 'PUT', // Assuming PUT is the correct method
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('HTTP Error Response:', errorText);
        throw new Error(`HTTP error ${response.status}: ${errorText}`);
      }

      const result = await response.json();
      setApiResponse(result);
      console.log('API Response:', result);
      alert(result.message);

    } catch (error) {
      console.error('Fetch Error:', error);
      alert(`An error occurred: ${error.message}`);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      const data = {
        id: formData.id,
        Relationship: formData.relationship,
        Firstname: formData.firstname,
        Lastname: formData.lastname,
        Phone: formData.phone,
        Dob: formData.dob,
        Gender: formData.gender,
        Country: formData.country,
        Nationalid: formData.nationalId,
      };
      updatePatientRecord(data);
    }
  };

  return (
    <div>
      <div className="desktop-4-p36">
        <div className="auto-group-vzd6-XiC">
          <div className="group-182-qit">
            <Navbar />
          </div>
          <Link to="/profile">
            <div className="group-1261154838-oUg">
              <img className="group-1261154072-XQg" src="./images/group-1261154072-2y2.png" alt='group-1261154072-2y2' />

              <p className="back-EZz mt-3">Back</p>

            </div>
          </Link>
          <div className="auto-group-dvg4-y1n">
            <div className="auto-group-z2j6-7Nt">
              <div className="frame-1261154254-SRA">
                <img className="rectangle-39635-mCY" src="./images/rectangle-39634.png" alt='rectangle-39634' />
                <div className="aha-Use">{username}</div>
              </div>
              <div className="auto-group-4yhs-aQt">
                <div className="group-1261154801-iGC">
                  <div className="group-1261154793-RRW">
                    <Link to="/records">
                      <div className="auto-group-ymq6-Ym2">

                        <img className="vector-fak" src="./images/records-icons.png" alt='vector-Czp' />

                        <div className="records-Boz">Records</div>
                      </div>
                    </Link>
                    <Link to="/personal">
                      <div className="auto-group-htui-J7v">

                        <img className="icon-cuJ" src="./images/personal-icon.png" alt='icon-XLg' />

                        <div className="personal-Ynx">Personal</div>
                      </div>
                    </Link>
                    <Link to="/medical">
                      <div className="auto-group-oqka-52C">
                        <img className="group-1261154885-oTz" src="./images/group-1261154885.png" alt='group-1261154885' />
                        <div className="medical-X96">Medical</div>
                      </div>
                    </Link>
                    <Link to='/lifestyle'>
                      <div className="auto-group-umic-FKz">
                        <img className="vector-n52" src="./images/vector-QTe.png" alt='vector-QTe' />
                        <div className="life-style-ttk">Life Style</div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="my-zwrrar-PKi">My Zwrrar</div>
                <div className="group-1261154808-iMz">
                  <div className="rectangle-39639-Fsi">
                  </div>
                  <div className="my-programs-yoi">My Programs</div>
                  <img className="group-1261154897-UVa" src="./images/group-1261154897.png" alt='group-1261154897' />
                </div>
              </div>
            </div>
            <div className="group-1261154803-AtC">
              <form className="contact-form1" onSubmit={handleSubmit}>
                <h2>View</h2>
                <div className="row">
                  <div className="col-xl-6 col-md-12">
                    <label htmlFor="id" className='poppins-regular zw_text_color zw_20'></label>
                    <input
                      type="hidden"
                      id="1"
                      name="id"
                      placeholder='ID'
                      value={formData.id}
                      onChange={handleChange}
                      required
                    />
                    {errors.id && <span className="error">{errors.id}</span>}
                  </div>

                </div>
                <div className="row">
                  <div className="col-xl-6 col-md-12">
                    <label htmlFor="firstname" className='poppins-regular zw_text_color zw_20 '>First Name</label>
                    <input
                      type="text"
                      id="firstname"
                      name="firstname"
                      className='inputborder poppins-regular'
                      placeholder='First Name'
                      value={formData.firstname}
                      onChange={handleChange}
                      required

                    />
                    {errors.firstname && <span className="error">{errors.firstname}</span>}
                  </div>
                  <div className="col-xl-6 col-md-12">
                    <label htmlFor="lastname" className='poppins-regular zw_text_color zw_20 '>Last Name</label>
                    <input
                      type="text"
                      id="lastname"
                      name="lastname"
                      className='inputborder poppins-regular'
                      placeholder='Last Name'
                      value={formData.lastname}
                      onChange={handleChange}
                      required
                    />
                    {errors.lastname && <span className="error">{errors.lastname}</span>}
                  </div>

                </div>
                <div className="row">
                  <div className="col-xl-6 col-md-12">
                    <label htmlFor="relationship" className='poppins-regular zw_text_color zw_20'>Relationship</label>
                    <input
                      type="text"
                      id="relationship"
                      name="relationship"
                      className='inputborder poppins-regular'
                      placeholder='Relationship'
                      value={formData.relationship}
                      onChange={handleChange}
                      required
                    />
                    {errors.relationship && <span className="error">{errors.relationship}</span>}
                  </div>
                  <div className="col-xl-6 col-md-12">
                    <label htmlFor="gender" className='poppins-regular zw_text_color zw_20  '>Gender</label>
                    <select
                      id="gender"
                      name="gender"
                      className='inputborder poppins-regular'
                      value={formData.gender}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </select>
                    {errors.gender && <span className="error">{errors.gender}</span>}
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-6 col-md-12">
                    <label htmlFor="dob" className='poppins-regular zw_text_color zw_20'>Date of Birth</label>
                    <input
                      type="date"
                      id="dob"
                      name="dob"
                      className='inputborder poppins-regular'
                      placeholder='Date of Birth'
                      value={formData.dob}
                      onChange={handleChange}
                      required

                    />
                    {errors.dob && <span className="error">{errors.dob}</span>}
                  </div>
                  <div className="col-xl-6 col-md-12">
                    <label htmlFor="age" className='poppins-regular zw_text_color zw_20'>Age</label>
                    <input
                      type="text"
                      id="age"
                      name="age"
                      placeholder='Age'
                      value={formData.age}
                      onChange={handleChange}
                      className='inputborder poppins-regular'
                      required
                    />
                    {errors.age && <span className="error">{errors.age}</span>}
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-6 col-md-12">
                    <label htmlFor="country" className='poppins-regular zw_text_color zw_20'>Country</label>
                    <input
                      type="text"
                      id="country"
                      name="country"
                      placeholder='Country'
                      value={formData.country}
                      onChange={handleChange}
                      className='inputborder poppins-regular'
                      required
                    />
                    {errors.country && <span className="error">{errors.country}</span>}
                  </div>
                  <div className="col-xl-6 col-md-12">
                    <label htmlFor="nationalId" className='poppins-regular zw_text_color zw_20'>National ID</label>
                    <input
                      type="text"
                      id="nationalId"
                      name="nationalId"
                      placeholder='National ID'
                      value={formData.nationalId}
                      className='inputborder poppins-regular'
                      onChange={handleChange}
                      required
                    />
                    {errors.nationalId && <span className="error">{errors.nationalId}</span>}
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-6 col-md-12">
                    <label htmlFor="phone" className='poppins-regular zw_text_color zw_20'>Phone Number</label>
                    <input
                      type="text"
                      id="phone"
                      name="phone"
                      placeholder='Phone Number'
                      value={formData.phone}
                      onChange={handleChange}
                      className='inputborder poppins-regular'
                      required
                    />
                    {errors.phone && <span className="error">{errors.phone}</span>}
                  </div>

                </div>
                <button type="submit" className='poppins-regular zw_text_color  link_btn zw_20 mx-auto mt-5 border-0' >Send</button>
              </form>
              {/* {apiResponse && (
                <div className="api-response">
                  <h3>Response:</h3>
                  <pre>{JSON.stringify(apiResponse, null, 2)}</pre>
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Personal;
