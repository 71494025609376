import React, {useContext} from 'react'
import '../../../Style/Medical.css';
import { Link } from 'react-router-dom';
import Navbar from '../../Layout/Navbar';
import Footer from '../../Layout/Footer';
import { Context } from '../../../Context';


function Medical() {
  const {username} = useContext(Context);

  return (
    <div>
      <Navbar />
      <div className="desktop-5-vEY">
        <div className="auto-group-i5de-DUY">
          <div className="group-182-7pp">
            {/* <img className="logo-Fg8" src="./images/logo-4uz.png"/>
      <div className="auto-group-ahac-ztc">
        <div className="group-1261154647-j5W">
          <div className="home-EHA">Home</div>
          <div className="about-kmJ">About</div>
          <div className="join-as-provider-gur">Join as provider</div>
          <div className="get-care-S8L">Get care</div>
        </div>
        <img className="group-179-ye4" src="./images/group-179-4YC.png"/>
        <div className="auto-group-pk4u-JAY">Aha</div>
      </div> */}
          </div>
          <Link to="/profile">
            <div className="group-1261154838-vhi">
              <img className="group-1261154072-3GY" src="./images/group-1261154072-2y2.png" alt='group-1261154072-2y2'/>
              <p className="back-ZEt mb-0">Back</p>
            </div>
          </Link>
          <div className="auto-group-8tme-hM6">
            <div className="auto-group-gj4g-rDz">
              <div className="frame-1261154254-C2x">

                <img className="rectangle-39635-uxx" src="./images/medical-profile.png" alt='rectangle-39635-JkU'/>
                <div className="noha-abdullah-de4">{username}</div> 
              </div>
              <div className="auto-group-qfhw-wue">
                <div className="group-1261154801-VgG">
                  <div className="group-1261154793-bzC">
                    <Link to="/records">
                      <div className="auto-group-zfs2-k6Q">

                        <img className="vector-For" src="./images/records-icons.png" alt='vector-Czp'/>

                        <div className="records-mnC">Records</div>
                      </div>
                    </Link>
                    <Link to="/personal">
                      <div className="auto-group-wngk-Gyr">
                        <img className="icon-QaG" src="./images/icon.png" alt='icon'/>
                        <div className="personal-jsS">Personal</div>
                      </div>
                    </Link>
                    <div className="auto-group-xx8y-sTr">

                      <img className="group-1261154885-oMW" src="./images/medical-icon.png" alt='medical-icons' />

                      <div className="medical-XYQ">Medical</div>
                    </div>
                    <Link to='/lifestyle'>
                    <div className="auto-group-jeyr-TBA">
                      <img className="vector-zB6" src="./images/vector-QTe.png" alt='vector-QTe'/>
                      <div className="life-style-KjA">Life Style</div>
                    </div>
                    </Link>
                  </div>
                </div>
                <div className="my-zwrrar-E5S">My Zwrrar</div>
                <div className="group-1261154808-Mfr">
                  <div className="rectangle-39639-tvg">
                  </div>
                  <div className="my-programs-RQp">My Programs</div>
                  <img className="group-1261154897-XTr" src="./images/group-1261154897.png" alt='group-1261154897'/>
                </div>
              </div>
            </div>
            <div className="group-1261154855-pxk">
              <div className="group-1261154854-ZQY">
                <div className="view-patient-Jsv">View Patient</div>
                <div className="group-1261154853-RxY">
                  <div className="injuries-Bgp">Injuries</div>
                  <div className="group-1261154804-igk">
                    <div className="group-1261154844-Fgg">
                      <div className="have-you-had-any-injuries-the-past-CM2">Have you had any injuries the past?</div>
                      <div className="auto-group-h2q4-KAk">
                        <div className="group-1261154843-eyi">
                          <input type='radio' name='check' className="radiobtnicon radio-button-zXn" />
                          <div className="yes-88C">Yes</div>
                        </div>
                        <div className="group-1261154842-SPn">
                          <input type='radio' name='check' className="radiobtnicon radio-button-PZv" />
                          <div className="no-XRE">No</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className="group-1261154853-RxY">
                  <div className="injuries-Bgp">Past Medication</div>
                  <div className="group-1261154804-igk">
                    <div className="group-1261154844-Fgg">
                      <div className="have-you-had-any-injuries-the-past-CM2">Have you been on medications in the past?</div>
                      <div className="auto-group-h2q4-KAk">
                        <div className="group-1261154843-eyi">
                          <input type='radio' name='check' className="radiobtnicon radio-button-zXn" />
                          <div className="yes-88C">Yes</div>
                        </div>
                        <div className="group-1261154842-SPn">
                          <input type='radio' name='check' className="radiobtnicon radio-button-PZv" />
                          <div className="no-XRE">No</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="group-1261154853-RxY">
                  <div className="injuries-Bgp">Allergies</div>
                  <div className="group-1261154804-igk">
                    <div className="group-1261154844-Fgg">
                      <div className="have-you-had-any-injuries-the-past-CM2">Are you allergic to anything?</div>
                      <div className="auto-group-h2q4-KAk">
                        <div className="group-1261154843-eyi">
                          <input type='radio' name='check' className="radiobtnicon radio-button-zXn" />
                          <div className="yes-88C">Yes</div>
                        </div>
                        <div className="group-1261154842-SPn">
                          <input type='radio' name='check' className="radiobtnicon radio-button-PZv" />
                          <div className="no-XRE">No</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="group-1261154853-RxY">
                  <div className="injuries-Bgp">Chronic Diseases</div>
                  <div className="group-1261154804-igk">
                    <div className="group-1261154844-Fgg">
                      <div className="have-you-had-any-injuries-the-past-CM2">Do you have any chronic diseases?</div>
                      <div className="auto-group-h2q4-KAk">
                        <div className="group-1261154843-eyi">
                          <input type='radio' name='check' className="radiobtnicon radio-button-zXn" />
                          <div className="yes-88C">Yes</div>
                        </div>
                        <div className="group-1261154842-SPn">
                          <input type='radio' name='check' className="radiobtnicon radio-button-PZv" />
                          <div className="no-XRE">No</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="group-1261154853-RxY">
                  <div className="injuries-Bgp">Current Medication</div>
                  <div className="group-1261154804-igk">
                    <div className="group-1261154844-Fgg">
                      <div className="have-you-had-any-injuries-the-past-CM2">Are you taking any medicines at the moment?</div>
                      <div className="auto-group-h2q4-KAk">
                        <div className="group-1261154843-eyi">
                          <input type='radio' name='check' className="radiobtnicon radio-button-zXn" />
                          <div className="yes-88C">Yes</div>
                        </div>
                        <div className="group-1261154842-SPn">
                          <input type='radio' name='check' className="radiobtnicon radio-button-PZv" />
                          <div className="no-XRE">No</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="group-1261154853-RxY">
                  <div className="injuries-Bgp">Surgeries</div>
                  <div className="group-1261154804-igk">
                    <div className="group-1261154844-Fgg">
                      <div className="have-you-had-any-injuries-the-past-CM2">Did you undergo any surgeries?</div>
                      <div className="auto-group-h2q4-KAk">
                        <div className="group-1261154843-eyi">
                          <input type='radio' name='check' className="radiobtnicon radio-button-zXn" />
                          <div className="yes-88C">Yes</div>
                        </div>
                        <div className="group-1261154842-SPn">
                          <input type='radio' name='check' className="radiobtnicon radio-button-PZv" />
                          <div className="no-XRE">No</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Medical