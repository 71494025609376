import React, { useState, useEffect } from 'react';
import '../../../Style/Appointment.css'
import { Link } from 'react-router-dom';
import Navbar from '../../Layout/Navbar';
import Footer from '../../Layout/Footer';

function Appointment() {
  const [selectedOption, setSelectedOption] = useState('');

  const handleCheckboxChange = (value) => {
    setSelectedOption(value);
  };

  // const pastData = [
  //   {
  //     "id": 1,
  //     "Doctorimg": '../../../../images/Rectangle 39755.png',
  //     "Drname": 'Dr.Mohammed John',
  //     "telemedicineIcon": '../../../../images/video-icon.svg',
  //     "telemedicine": 'Telemedicine',
  //     "patientIcon": '../../../../images/patient-icon-app.svg',
  //     "patientnameholder": 'Patient name',
  //     "patientname": 'Sultan Ali(My Self)',
  //     "appointmentIcon": '../../../../images/Appoint-id.svg',
  //     "appointmentholder": 'Appointment ID',
  //     "appointmentId": 'ADC356475',
  //     "calenderIcon": '../../../../images/cale-icon-app.svg',
  //     "Date": '02 Sep 2026',
  //     "timeIcon": '../../../../images/time-icon-app.svg',
  //     "time": '6:00 PM',
  //     "reportIcon": '../../../../images/Report-icon-app.svg',
  //     "report": 'View Report',

  //   },
  // ]

  const [currentView, setCurrentView] = useState('Upcoming');
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchAppointments();
  }, []);

  const fetchAppointments = async () => {
    try {
      const response = await fetch('https://zwarra.biztechnosys.com/api/getappointmentbypatient/10', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Cookie': 'zwarra_session=ehKPVgnMkHItOwPyeIxWiVODQtDFbQSmkUJv8UsJ'
        }
      });
      const data = await response.json();
      setAppointments(data.appointment_records);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching appointments:', error);
      setLoading(false);
    }
  };

  const handleViewChange = (view) => {
    setCurrentView(view);
  };
  return (
    <div>
      <Navbar />
      <div className="appiontments-YKE">
        <div className="auto-group-uzrn-4Ha">
          <div className="auto-group-sqbn-mSt">

          </div>
          <Link to="/profile">
            <div className="auto-group-fzyn-1Gg">
              <img className="group-1261154072-jTa" src="/images/group-1261154072-2y2.png" alt='' />
              <p className="poppins-medium zw_18 zw_333333" style={{ margin: '0' }}>Back</p>
            </div>
          </Link>
          <div className="group-1261154802-aUC mt-5">
            <p className=" appointment-7y poppins-medium zw_24 zw_title_color mb-0">Appointment</p>
            <div className='d-flex'>
              <div className='d-flex' >
                <select
                  id="patientcode"
                  name="patientcode"
                  className=" zw_form_control zw_secondary poppins-regular zw_16 pe-5"
                  style={{ borderRadius: "8px", paddingLeft: "30px" }}
                >
                  <option value="" >
                    Selected patient

                  </option>
                  <option value="abc">
                    abc
                  </option>
                  <option value="def">
                    def
                  </option>
                </select>
                {/* <i className="icon-down-arrow zw_icon_drop" ></i> */}
              </div>
              <img className="group-1261154799-R1W" src="/images/group-1261154799.png" alt='sda' data-bs-toggle="modal" data-bs-target="#iconBackdrop" />
              <div class="modal fade" id="iconBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" style={{ marginTop: "100px" }}>
                <div class="modal-dialog">
                  <div class="modal-content" style={{ width: "700px" }}>
                    <div >

                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>


                    {/* <div className="modal-body" style={{ margin: "25px" }} >

                                        <div class="modal-header">
                                            <h5 class="modal-title" id="staticBackdropLabel">Home Visit</h5>

                                        </div>
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="staticBackdropLabel">Telemedicine</h5>

                                        </div>
                                        <div >
                                            <h5 class="modal-title" id="staticBackdropLabel" style={{ margin: "10px" }}>All</h5>

                                        </div>

                                        <div class="d-grid gap-2" style={{ marginTop: "10px", height: "30px" }}>

                                            <button class="btn btn-primary" type="button" style={{ backgroundColor: "#AF2245", borderColor: "#AF2245" }}>Save</button>
                                        </div>

                                    </div>  */}
                    <div className="modal-body" style={{ margin: "25px" }} >


                      <div class="modal-header">
                        <div class="form-check" style={{ margin: "10px" }}>

                          <label className="form-check-label" htmlFor="homeVisit">
                            Home Visit
                          </label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="homeVisit"
                            value="homeVisit"
                            checked={selectedOption === "homeVisit"}
                            onChange={() => handleCheckboxChange("homeVisit")} style={{ marginLeft: "550px", marginTop: "-20px", backgroundColor: selectedOption === "homeVisit" ? "#AF2245" : "transparent", border: "none", borderColor: "none" }} />
                        </div>
                      </div>
                      <div class="modal-header">
                        <div class="form-check" style={{ margin: "10px" }}>

                          <label className="form-check-label" htmlFor="telemedicine">
                            Telemedicine
                          </label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="telemedicine"
                            value="telemedicine"
                            checked={selectedOption === "telemedicine"}
                            onChange={() => handleCheckboxChange("telemedicine")} style={{ marginLeft: "550px", marginTop: "-20px", backgroundColor: selectedOption === "telemedicine" ? "#AF2245" : "transparent", border: "none", borderColor: "none" }} />
                        </div>
                      </div>
                      <div class="modal-header">
                        <div class="form-check" style={{ margin: "10px" }}>

                          <label className="form-check-label" htmlFor="all">
                            All
                          </label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="all"
                            value="all"
                            checked={selectedOption === "all"}
                            onChange={() => handleCheckboxChange("all")} style={{ marginLeft: "550px", marginTop: "-20px", backgroundColor: selectedOption === "all" ? "#AF2245" : "transparent", border: "none", borderColor: "none" }} />
                        </div>
                      </div>
                      <div class="d-grid gap-2" style={{ marginTop: "10px", height: "30px" }}>
                        <button class="btn btn-primary" type="button" style={{ backgroundColor: "#AF2245", borderColor: "#AF2245" }}>Save</button>
                      </div>

                    </div>

                  </div>
                </div>
              </div>
            </div>

            {/* </div> */}

            {/* </div> */}
          </div>
          <div className="auto-group-4het-P4G">
            <div className="group-1261154801-LEQ">
              <div className="group-1261154793-TZv">
                <div className="auto-group-mqye-CXW" style={{ backgroundColor: '#AF2245' }}>
                  <img className="group-1261154899-8RA" src="/images/group-1261154899.png" alt='' />
                  <div className="poppins-medium zw_18 zw_text_fff">Appointments</div>
                </div>
                <Link to="/prescription">
                  <div className="auto-group-mqye-CXW">
                    <img className="group-1261154899-8RA" src="/images/prescription1-5W4.png" alt='' />
                    <div className="poppins-medium zw_18 zw_text_color">Prescription</div>
                  </div>
                </Link>
                <Link to="/reports">
                  <div className="auto-group-mqye-CXW">
                    <img className="group-1261154899-8RA" src="/images/group-1261154896-MBa.png" alt='' />
                    <div className="poppins-medium zw_18 zw_text_color">Reports</div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="auto-group-pzzj-Tdn">
              <div className="auto-group-vvxk-nvx">
                <button className={currentView === 'Upcoming' ? 'active group-1261154912-vnG' : 'group-1261154912-vnG'} onClick={() => handleViewChange('Upcoming')}>Upcoming</button>
                <button className={currentView === 'Pending' ? 'active group-1261154912-vnG' : 'group-1261154912-vnG'} onClick={() => handleViewChange('Pending')}>Pending</button>
                <button className={currentView === 'Past' ? 'active group-1261154912-vnG' : 'group-1261154912-vnG'} onClick={() => handleViewChange('Past')}>Past</button>
                <button className={currentView === 'Cancelled' ? 'active group-1261154912-vnG' : 'group-1261154912-vnG'} onClick={() => handleViewChange('Cancelled')}>Cancelled</button>
              </div>


              <div className="rectangle-39641-w56" >
                {currentView === 'Upcoming' && (
                  <div className='poppins-medium zw_18 zw_text_color d-flex justify-content-center align-items-center h-100'>
                    <p className=''>No Appointments</p>
                  </div>
                )}
                {currentView === 'Pending' && (
                  <div className='poppins-medium zw_18 zw_text_color d-flex justify-content-center align-items-center h-100'>
                    <p className=''>Pending Appointments</p>
                  </div>
                )}





                {currentView === 'Past' && (
                  appointments.map((pitems) => {
                    const patients = JSON.parse(pitems.Patients);
                    const firstPatient = patients[0];
                    const fullName = `${firstPatient.Firstname} ${firstPatient.Lastname}`;

                    return (
                      <div className='d-flex gap-4 p-5' key={pitems.id}>
                        <div className=''>
                          <img src="./images/Rectangle 39755.png " alt='' />
                        </div>
                        <div className='w-100'>
                          <p className='poppins-semibold zw_18 zw_text_color'>{pitems.Healthcare}</p>
                          <div className='d-flex gap-2 mb-1'>
                            <img src="./images/video-icon.svg " alt='' />
                            <span className='poppins-medium zw_16'>{pitems.Servicename}</span>
                          </div>
                          <div className='d-flex gap-2 my-2'>
                            <img src="./images/patient-icon-app.svg " alt='' />
                            <p className='poppins-medium zw_16 zw_secondary mb-0'>{pitems.patientnameholder}</p>
                            <p className="poppins-medium zw_16 zw_text_color mb-0">
                              <span className="grey-color">Patient name :</span> {fullName}
                            </p>
                          </div>
                          <div className='d-flex gap-2 my-2'>
                            <img src="./images/Appoint-id.svg " alt='' />
                            <p className='poppins-medium zw_16 zw_secondary mb-0'>{pitems.appointmentholder}</p>
                            <p className='poppins-medium zw_16 zw_text_color mb-0'>
                              <span className="grey-color">  Appointment ID : </span> {pitems.id}</p>
                          </div>
                          <div className='d-flex justify-content-between'>
                            <div className='d-flex gap-2 my-2'>
                              <img src="./images/cale-icon-app.svg " alt='' />
                              <p className='poppins-medium zw_16 zw_text_color mb-0 me-4'>{pitems.Date}</p>
                              <img src="./images/time-icon-app.svg " alt='' />
                              <p className='poppins-medium zw_16 zw_text_color mb-0'>{pitems.Timeslot}</p>
                            </div>
                            <button className='border-0 bg-report-app d-flex align-items-end px-4 pb-2'>
                              <img className='reporticonapp me-3' src="./images/Report-icon-app.svg" alt='' />
                              <p className='poppins-medium zw_16 zw_title_color mb-0'>View Report</p>
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}
                {currentView === 'Cancelled' && (
                  <div className='poppins-medium zw_18 zw_text_color d-flex justify-content-center align-items-center h-100' style={{ justifyContent: "center", display: "flex", width: "100%" }}>
                    <p>No Appointments</p>
                  </div>
                )}
              </div>


            </div>
          </div>
        </div>
        {/* <div className="group-1000002072-4vQ">
                    <div className="group-1261154099-Bk8">
                        <img className="group-1261154093-WnQ" src="./images/group-1261154093-ACC.png" />
                        <img className="group-1261154040-etc" src="./images/group-1261154040-Dbr.png" />
                    </div>
                    <div className="auto-group-zuep-b3A">
                        <p className="company-LWY">Company</p>
                        <div className="frame-37135-G9J">
                            <p className="home-ntL">Home</p>
                            <p className="about-jYg">About</p>
                            <p className="join-as-provider-gTv">Join as provider</p>
                            <p className="get-care-pq2">Get Care</p>
                            <p className="join-us-B9n">Join Us</p>
                        </div>
                    </div>
                    <div className="auto-group-yuqv-KWt">
                        <p className="laboratory-FfS">Laboratory</p>
                        <div className="frame-37136-Q2Y">
                            <p className="general-test-LRz">General test</p>
                            <p className="blood-test-tCc">Blood test</p>
                            <p className="urine-test-2Zi">Urine test</p>
                            <p className="dna-test-AR2">DNA Test</p>
                        </div>
                    </div>
                    <div className="auto-group-dyqn-Jn8">
                        <p className="contact-us-eb6">Contact Us</p>
                        <p className="item-923489934721-nhJ">+923489934721</p>
                        <p className="zwaarahealthcom-Jfe">Zwaara@health.com</p>
                        <p className="riadh-2343-saudi-arabia-ptt">Riadh 2343, Saudi Arabia,</p>
                    </div>
                </div> */}
        <Footer />
      </div>
    </div>
  )
}

export default Appointment
