import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../../Layout/Navbar';
import Footer from '../../Layout/Footer';

function Perception() {
    const [currentView, setCurrentView] = useState('New');
    const [selectedOption, setSelectedOption] = useState('');

    const handleViewChange = (view) => {
        setCurrentView(view);
    };

    const handleCheckboxChange = (value) => {
        setSelectedOption(value);
    };

    return (
        <div>
            <Navbar />
            <div className="appiontments-YKE">
                <div className="auto-group-uzrn-4Ha">
                    <div className="auto-group-sqbn-mSt"></div>
                    <Link to="/profile">
                        <div className="auto-group-fzyn-1Gg">
                            <img className="group-1261154072-jTa" src="/images/group-1261154072-2y2.png" alt='' />
                            <p className="poppins-medium zw_18 zw_333333" style={{ margin: '0' }}>Back</p>
                        </div>
                    </Link>
                    <div className="group-1261154802-aUC mt-5">
                        <p className="appointment-7yv poppins-medium zw_24 zw_title_color">Prescription</p>

                        <div className='d-flex'>
                            <div className='d-flex'>
                                <select
                                    id="patientcode"
                                    name="patientcode"
                                    className=" zw_form_control zw_secondary poppins-regular zw_16 pe-5"
                                    style={{ borderRadius: "8px", paddingLeft: "30px" }}
                                >
                                    <option value="">Selected patient</option>
                                    <option value="abc">abc</option>
                                    <option value="def">def</option>
                                </select>
                            </div>
                            <img className="group-1261154799-R1W" src="/images/group-1261154799.png" alt='sda' data-bs-toggle="modal" data-bs-target="#iconBackdrop" />
                        </div>

                        <div className="modal fade" id="iconBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" style={{ marginTop: "100px" }}>
                            <div className="modal-dialog">
                                <div className="modal-content" style={{ width: "700px" }}>
                                    <div>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body" style={{ margin: "25px" }}>
                                        <div className="modal-header">
                                            <div className="form-check" style={{ margin: "10px" }}>
                                                <label className="form-check-label" htmlFor="homeVisit">Home Visit</label>
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="homeVisit"
                                                    value="homeVisit"
                                                    checked={selectedOption === "homeVisit"}
                                                    onChange={() => handleCheckboxChange("homeVisit")}
                                                    style={{ marginLeft: "550px", marginTop: "-20px", backgroundColor: selectedOption === "homeVisit" ? "#AF2245" : "transparent", border: "none" }}
                                                />
                                            </div>
                                        </div>
                                        <div className="modal-header">
                                            <div className="form-check" style={{ margin: "10px" }}>
                                                <label className="form-check-label" htmlFor="telemedicine">Telemedicine</label>
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="telemedicine"
                                                    value="telemedicine"
                                                    checked={selectedOption === "telemedicine"}
                                                    onChange={() => handleCheckboxChange("telemedicine")}
                                                    style={{ marginLeft: "550px", marginTop: "-20px", backgroundColor: selectedOption === "telemedicine" ? "#AF2245" : "transparent", border: "none" }}
                                                />
                                            </div>
                                        </div>
                                        <div className="modal-header">
                                            <div className="form-check" style={{ margin: "10px" }}>
                                                <label className="form-check-label" htmlFor="all">All</label>
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="all"
                                                    value="all"
                                                    checked={selectedOption === "all"}
                                                    onChange={() => handleCheckboxChange("all")}
                                                    style={{ marginLeft: "550px", marginTop: "-20px", backgroundColor: selectedOption === "all" ? "#AF2245" : "transparent", border: "none" }}
                                                />
                                            </div>
                                        </div>
                                        <div className="d-grid gap-2" style={{ marginTop: "10px", height: "30px" }}>
                                            <button className="btn btn-primary" type="button" style={{ backgroundColor: "#AF2245", borderColor: "#AF2245" }}>Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="auto-group-4het-P4G">
                        <div className="group-1261154801-LEQ">
                            <div className="group-1261154793-TZv">
                                <Link to="/appointment">
                                    <div className="auto-group-mqye-CXW">
                                        <img className="group-1261154899-8RA" src="/images/group-1261154899-DQY.png" alt='' />
                                        <div className="poppins-medium zw_18 zw_text_color">Appointments</div>
                                    </div>
                                </Link>
                                <div className="auto-group-mqye-CXW" style={{ backgroundColor: '#AF2245' }}>
                                    <img className="group-1261154899-8RA" src="/images/prescription1.png" alt='' />
                                    <div className="poppins-medium zw_18 zw_text_fff">Prescription</div>
                                </div>
                                <Link to="/reports">
                                    <div className="auto-group-mqye-CXW">
                                        <img className="group-1261154899-8RA" src="/images/group-1261154896-MBa.png" alt='' />
                                        <div className="poppins-medium zw_18 zw_text_color">Reports</div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="auto-group-pzzj-Tdn">
                            <div className="auto-group-vvxk-nvx">
                                <button className={currentView === 'New' ? 'active group-1261154912-vnG' : 'group-1261154912-vnG'} onClick={() => handleViewChange('New')}>New</button>
                                <button className={currentView === 'Previous' ? 'active group-1261154912-vnG' : 'group-1261154912-vnG'} onClick={() => handleViewChange('Previous')}>Previous</button>
                            </div>

                            <div className="auto-group-zydn-6se">
                                <div className='poppins-medium zw_18 zw_black head-list'>
                                    <div>Doctor</div>
                                    <div>Name</div>
                                    <div>Time</div>
                                    <div>Status</div>
                                    <div>Order</div>
                                </div>
                                <div className="poppins-semibold zw_18 zw_text_color found-2YL">
                                    {currentView === 'New' && (
                                        <p>New Prescriptions are currently available.</p>
                                    )}
                                    {currentView === 'Previous' && (
                                        <p>These are your previous prescriptions.</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default Perception;
