import React from "react";
import "../Style/CareBussiness.css";
import { Link } from "react-router-dom";
// import img1 from "../../src/assets/img/careimg1.png";
// import img2 from "../../src/assets/img/careimg2.jpg";
// import img3 from "../../src/assets/img/careimge3.jpg";
// import img4 from "../../src/assets/img/careimg4.jpg";
import img8 from "../../src/assets/img/image 87.png";
import img9 from "../../src/assets/img/Group 1261155856 1.png";
import img10 from "../../src/assets/img/Rectangle 39715.png";
import img11 from "../../src/assets/img/Rectangle 39717.png";
import img12 from "../../src/assets/img/uncheck.svg";
import img13 from "../../src/assets/img/Rectangle 39716.png";
import Navbar from "../Components/Layout/Navbar";
import Footer from "../Components/Layout/Footer";

function BussinessBanner() {
  const indicard2data = [
    {
      title: "Easy treatment journey",
      image: "./images/careimg1.png",
      description: " Quick access to the doctors with different specialization. Text chat or video call, around the clock, with high privacy and confidentiality. Home visits and clinical examination without waiting inclinics for long hours"
    },
    {
      title: "Counseling and integrated care",
      image: "./images/careimg2.jpg",
      description: "A suitable diagnosis of your condition, issuance of prescriptions and e medical reports, and dispensing medicines while you are at home."
    },
    {
      title: "Integrated treatment programmes",
      image: "./images/careimge3.jpg",
      description: "Treatment plans include consultations, clinical and laboratory examinations periodically , with follow-up of your condition with a specialist doctor without the hassle of going to hospitals"
    },
    {
      title: "Clear costs and great care",
      image: "./images/careimg4.jpg",
      description: "Medical services at an affordable price with the possibility of quick linking with various insurance companies"
    },

  ];
  return (
    <>
      <Navbar />
      <section className="cb-banner" style={{ marginTop: "100px" }}>
        <div className="container py-5">
          <div className=" d-flex justify-content-between">
            <div className="">
              <h1 className="zw_46 poppins-extrabold text_gradient" style={{ fontFamily: "Poppins, 'Source Sans Pro'" }}>
                Zuwara Business
              </h1>
              <p className="poppins_bold zw_46 text_gradient" style={{ fontFamily: "Poppins, 'Source Sans Pro'" }} >
                Your partner to enhance your employee&#39;s health and
                well-being
              </p>
              <p className="poppins_regular zw_16 zw_text_color" style={{ fontFamily: "Poppins, 'Source Sans Pro'" }}>
                Medical solutions for companies that aim to provide medical
                services to their employee to maintain and improve their health
                by providing wide coverage to allow them obtain medical services
                anywhere and at any time.
              </p>
              <div>
                <Link to="/contactus">
                  <div className="container-12">
                    <div className="my-4">
                      <button className="px-5 py-3 poppins_regular zw_text_fff zw_bg_gradient zw_18" style={{ border: "none", borderRadius: "5px", fontFamily: "Poppins, 'Source Sans Pro'" }}>
                        Contact Us
                      </button>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="mt-5">
                <img src={img8} alt="" className="ellipseim" />
                <span className="poppins_regular zw_16 zw_text_color mx-3" style={{ fontFamily: "Poppins, 'Source Sans Pro'" }}>
                  {" "}
                  A platform approved by the Saudi Ministry of health
                </span>
              </div>
            </div>
            <div className="">
              <img src={img9} alt="" className="banner_img_size" />
            </div>
          </div>
        </div>
      </section>
      <section className="my-5">
        <div className="container ">
          <div className="row">
            <div className="col text-center">
              <h1 className="mt-5 poppins_bold zw_46 text_gradient" style={{ fontFamily: "Poppins, 'Source Sans Pro'" }}>
                Why Zuwarh Business
              </h1>
            </div>
          </div>
          <div className="row my-3">
            <div className="col text-center">
              <p className="poppins_regular zw_18 " style={{ fontFamily: "Poppins, 'Source Sans Pro'" }}>
                We support you in apply occupational safety and health to
                enhance loyality of all of your employees
              </p>
            </div>
          </div>
          <section className="">
            <div className="container">
              <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
                {/* <!-- Card  --> */}
                {indicard2data.map((indiitem2) => {
                  return (
                    <div className=" my-4">
                      <div className="card get-size-2 ">
                        <img className="iv-drip-2" src={indiitem2.image} />
                        <div className="">
                          <p className=" poppins-bold zw_22 zw_text_color">

                            {indiitem2.title}
                          </p>
                          <p className="poppins-regular zw_18">

                            {indiitem2.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
        </div>
      </section>

      <section className="custom-section">
        <div
          className="container px-4 py-4 rounded"
          style={{ backgroundColor: "#F6F7F9" }}
        >
          <div className=" busines-page">
            <div className="images-column">
              <div>
                <img src={img10} alt="" className="cardb_img_size" />
              </div>
              <div className="mt-5">
                <img src={img13} alt="" className="cardb_img_size" />
              </div>
            </div>
            <div className="content-column mx-5">
              <h1 className="poppins-semibold zwaara_title" style={{ fontFamily: "Poppins, 'Source Sans Pro'" }}>
                Our programs for companies and groups
              </h1>
              <p className="poppins_regular zw_16 zw_secondary my-3" style={{ fontFamily: "Poppins, 'Source Sans Pro'" }}>
                Health setbacks do not only affect individuals, but also in the
                companies they work, insurance costs rise, morale declines,
                productivity decreases, and others. Therefore, our solutions are
                part of today's business world, which is accelerating and new
                things have been imposed on employers to take more care and
                attention for their employee.
              </p>
              <ul className="mt-4 p-0">
                <li className="my-2">
                  <img src={img12} alt="Icon 2" />
                  <span className="mx-4 zw_16 zw_002a56 poppins_regular" style={{ fontFamily: "Poppins, 'Source Sans Pro'" }}>
                    Medical and psychological counseling
                  </span>
                </li>
                <li className="my-2">
                  <img src={img12} alt="Icon 1" />
                  <span className="mx-4 zw_16 zw_002a56 poppins_regular" style={{ fontFamily: "Poppins, 'Source Sans Pro'" }}>
                    Chronic Disease Management Program
                  </span>
                </li>
                <li className="my-2">
                  <img src={img12} alt="Icon 1" />
                  <span className="mx-4 zw_16 zw_002a56 poppins_regular" style={{ fontFamily: "Poppins, 'Source Sans Pro'" }}>
                    Routine tests package for employees
                  </span>
                </li>
                <li className="my-2">
                  <img src={img12} alt="Icon 1" />
                  <span className="mx-4 zw_16 zw_002a56 poppins_regular" style={{ fontFamily: "Poppins, 'Source Sans Pro'" }}>
                    Seasonal vaccinations for employees
                  </span>
                </li>
                <li className="my-2">
                  <img src={img12} alt="Icon 1" />
                  <span className="mx-4 zw_16 zw_002a56 poppins_regular" style={{ fontFamily: "Poppins, 'Source Sans Pro'" }}>
                    Manage Health Certificate Reservations (Baladi)
                  </span>
                </li>
                <li className="my-2">
                  <img src={img12} alt="Icon 1" />
                  <span className="mx-4 zw_16 zw_002a56 poppins_regular" style={{ fontFamily: "Poppins, 'Source Sans Pro'" }}>
                    Hajj package for Hajj campaigns
                  </span>
                </li>
                <li className="my-2">
                  <img src={img12} alt="Icon 1" />
                  <span className="mx-4 zw_16 zw_002a56 poppins_regular" style={{ fontFamily: "Poppins, 'Source Sans Pro'" }}>
                    Pregnancy follow-up programme
                  </span>
                </li>
                <li className="my-2">
                  <img src={img12} alt="Icon 1" />
                  <span className="mx-4 zw_16 zw_002a56 poppins_regular" style={{ fontFamily: "Poppins, 'Source Sans Pro'" }}>
                    And more...
                  </span>
                </li>
              </ul>
            </div>
            <div className="banner-column">
              <div>
                <img src={img11} alt="" className="cardbt_img_size" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="my-5">
        <div className="container cb-banner py-5">
          <div className="row">
            <div className="col text-center py-5">
              <h1 className="zw_24 poppins-bold text_gradient my-3 py-3" >
                Take care of your employee health
              </h1>
              <p className="poppins_regular zw_16 zw_black my-4" style={{ fontFamily: "Poppins, 'Source Sans Pro'" }}>
                And join to the leading companies to ensure wellness and
                prosperity at work and in the workplace
              </p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default BussinessBanner;
