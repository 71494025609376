import React, { useState, useEffect, useContext } from 'react';
import '../../Style/login_popup.css';
import { Context } from '../../Context';

function AccountSetup_captcha() {
  const {setIsAuthenticated,setUsername,isLoading, setIsLoading, signupFormData, updateSignupFormData } = useContext(Context);
  const [formErrors, setFormErrors] = useState({});
  const [response, setResponse] = useState('');
  const [captcha, setCaptcha] = useState('');
  const [captchaInput, setCaptchaInput] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('sa');

  const countries = [
    { code: 'in', name: 'India', countryCode: '+91' },
    { code: 'sa', name: 'Saudi Arabia', countryCode: '+966' },
    { code: 'eg', name: 'Egypt', countryCode: '+20' },
    { code: 'au', name: 'Australia', countryCode: '+61' },
    { code: 'cn', name: 'Canada', countryCode: '+1' },
    { code: 'fr', name: 'France', countryCode: '+33' },
    { code: 'pk', name: 'Pakistan', countryCode: '+92' },
    { code: 'sl', name: 'Sri Lanka', countryCode: '+94' },
  ];

  const validate = (values, captchaInput) => {
    const errors = {};
    if (!values.Nationalid) {
      errors.Nationalid = 'National ID Number is required!';
    }
    if (!values.Country) {
      errors.Country = 'Please select a Country';
    }
    if (captchaInput !== captcha) {
      errors.Captcha = 'Captcha does not match!';
    }
    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateSignupFormData({ ...signupFormData, [name]: value });
    setFormErrors({ ...formErrors, [name]: '' });
  };

  const handleCountryChange = (event) => {
    const selectedCountry = countries.find(
      (country) => country.code === event.target.value );
    setSelectedCountry(selectedCountry.code);
    updateSignupFormData({ ...signupFormData, Country: selectedCountry.name });
    setFormErrors({ ...formErrors, Country: '' });
  };

  const getCountryCode = (selectedCountry) => {
    const country = countries.find((c) => c.code === selectedCountry);
    return country ? country.countryCode : '';
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate(signupFormData, captchaInput);
    setIsLoading(true);
  
    if (Object.keys(errors).length === 0) {
      setFormErrors({});
      console.log('step 4 data:', signupFormData);
      
      try {
        const res = await fetch('https://zwarra.biztechnosys.com/api/register', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Cookie: 'zwarra_session=TooHVo6rlNr54HH2FoB6T7F0JcpnoLdqI55ELBH3',
          },
          body: JSON.stringify(signupFormData),
        });
        const data = await res.json();
        console.log('Submitted data', data);
        if (data?.errors?.Email) {
          alert(data.errors.Email[0]);       
          setFormErrors({ ...formErrors, Email: data.errors.Email[0] });
        }
        if (data?.errors?.Phone) {
          alert(data.errors.Phone[0]);
          setFormErrors({ ...formErrors, Phone: data.errors.Phone[0] });
        }
        if (!data?.errors) {
          setResponse(data);
          let newData = data?.patient_registration;
          let responsDat = {
            Firstname: newData?.Firstname,
            Lastname: newData?.Lastname,
            Email: newData?.Email,
            Phone: newData?.Phone,
            Dob: newData?.Dob,
            Gender: newData?.Gender,
            Country: newData?.Country,
            Nationalid: newData?.Nationalid,
            Type: 'register',
          };
  
          sessionStorage.setItem('signupFormData', JSON.stringify({ ...responsDat }));
          
          sessionStorage.setItem('isAuthenticated', JSON.stringify(true));
          sessionStorage.setItem('username', JSON.stringify(`${newData?.Firstname || ''} ${newData?.Lastname || ''}`.trim()));
          
          setIsAuthenticated(true);
          setUsername(`${newData?.Firstname || ''} ${newData?.Lastname || ''}`.trim());
          const nextModal = new window.bootstrap.Modal(document.getElementById('OTP'));
          const currentModal = window.bootstrap.Modal.getInstance(document.getElementById('accountsetup_captcha'));
          currentModal.hide();
          nextModal.show();
        }
      } catch (error) {
        console.error('Error:', error);
        alert('An unexpected error occurred. Please try again later.');
      }finally{
        setIsLoading(false);
      }
    } else {
      setFormErrors(errors);
    }
  };
  

  const generateCaptcha = () => {
    const randomCaptcha = Math.floor(1000 + Math.random() * 9000);
    setCaptcha(randomCaptcha.toString());
  };

  useEffect(() => {
    generateCaptcha();
  }, []);

  return (
    <>
      <div className='zw_popup'>
        <div
          className='modal fade zw_captch_popup'
          id='accountsetup_captcha'
          role='dialog'
         data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true"
        >
          <div className='modal-dialog modal-dialog-centered' role='document'>
            <div className='modal-content'>
              <div className='modal-body'>
                <div className='btn-space'>
                  <button
                    type='button'
                    className='sps-dialog-close regv2back'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                  >
                    <i className='icon-close'></i>
                  </button>
                </div>
                <div className='container ps-5'>
                  <p className='poppins-regular zw_24 zw_9B9B9B mb-0'>
                    Step 3 completed of 4
                  </p>
                  <div className='line_indicator_container w-100'>
                    <div className='line-indicator-bg each_line_indicator active'></div>
                    <div className='line-indicator-bg each_line_indicator active'></div>
                    <div className='line-indicator-bg each_line_indicator active'></div>
                    <div className='line-indicator-bg each_line_indicator'></div>
                  </div>
                </div>
                <div className='card card-primary-light card-no-border last-child'>
                  <div className='login-brd'>
                    <form onSubmit={handleSubmit}>
                      <div className='input-group input-group-vert mb20 '>
                        <label className='zw_text_color poppins-regular zw_30'>
                          Country
                        </label>
                        <div className='form-group zw_form_group mb-0'>
                          <div className='zw_form_control zw_secondary poppins-regular zw_16'>
                            <div>
                              <div className='zw_country_sec zw_light_bg m-0 rounded-2'>
                                {selectedCountry && (
                                  <img
                                    className='form-icon img-ccode form-img zw_country_flag'
                                    src={`https://sanar-assets.com/flags/${selectedCountry}_64.png`}
                                    alt='Country Flags'
                                  />
                                )}
                                <span className='cuntry-plholder'>
                                  ({getCountryCode(selectedCountry)})
                                </span>
                              </div>
                              <select
                                id='countrycode'
                                name='Country'
                                className='input-no-border poppins-regular zw_22 zw_secondary w-100 pe-4 py-2'
                                style={{ border: 'none', paddingLeft: '110px', background: 'none' }}
                                value={selectedCountry}
                                onChange={handleCountryChange}
                              >
                                {countries.map((Country) => (
                                  <option
                                    key={Country.code}
                                    value={Country.code}
                                  >
                                    {Country.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        {formErrors.Country && (
                          <span className='sa-error-message' style={{ color: 'red' }}>
                            {formErrors.Country}
                          </span>
                        )}
                        <div className='form-group zw_form_group mb-0 mt-5'>
                          <input
                            type='text'
                            className='px-2 py-3 zw_form_control zw_secondary poppins-regular zw_16'
                            placeholder='National ID or Iqama number'
                            name='Nationalid'
                            value={signupFormData.Nationalid}
                            onChange={handleChange}
                            style={{ width: '100%' }}
                          />
                        </div>
                        {formErrors.Nationalid && (
                          <span className='sa-error-message' style={{ color: 'red' }}>
                            {formErrors.Nationalid}
                          </span>
                        )}
                      </div>
                      <label className='poppins-regular zw_22 zw_black'>
                        Enter Captcha
                      </label>
                      <div className='form-group zw_form_group'>
                        <div className='row '>
                          <div className='col-md-7'>
                            <input
                              type='text'
                              className='zw_form_control zw_secondary poppins-regular zw_16 px-2 py-3'
                              placeholder='Captcha'
                              maxLength={'4'}
                              value={captchaInput}
                              onChange={(e) => {
                                setCaptchaInput(e.target.value);
                                setFormErrors({ ...formErrors, Captcha: '' });
                              }}
                            />
                          </div>
                          <div className='col-md-3'>
                            <p className='captcha-text zw_secondary poppins-semibold zw_16 mt-3'>
                              {captcha}
                            </p>
                          </div>
                          <div className='col-md-2'>
                            <i
                              className='icon-reload sa-otp-ref'
                              onClick={generateCaptcha}
                            ></i>
                          </div>
                        </div>
                        {formErrors.Captcha && (
                          <span className='sa-error-message' style={{ color: 'red' }}>
                            {formErrors.Captcha}
                          </span>
                        )}
                      </div>
                      <button
                        type='submit'
                        className='zw_text_fff mt-3 poppins-regular zw_24 zw_bg_gradient zw_btn_18 zw_border_none p-2'
                        style={{
                          width: '80%',
                          margin: '0 10%',
                          borderRadius: '6px',
                        }}
                      >
                        {isLoading ? 'Processing...' : 'Continue'}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AccountSetup_captcha;
