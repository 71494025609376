import React, { useState, useContext, useEffect } from 'react';
import '../../Style/Signin_popup.css';
import { Link, useNavigate } from 'react-router-dom';
import { Context } from '../../Context';

function LoginPopup() {
    const { loginForm, setUsername, setIsAuthenticated, setLoginForm, isLoading, setIsLoading, error, setError, show, setShow } = useContext(Context);
    const [formErrors, setFormErrors] = useState({});
    const navigate = useNavigate();
    const handleClose = () => setShow(false);

    const countries = [
        { code: 'sa', name: 'Saudi Arabia', countryCode: '+966' },
        { code: 'in', name: 'India', countryCode: '+91' },
        { code: 'eg', name: 'Egypt', countryCode: '+20' },
        { code: 'au', name: 'Australia', countryCode: '+61' },
        { code: 'ca', name: 'Canada', countryCode: '+1' },
        { code: 'fr', name: 'France', countryCode: '+33' },
        { code: 'pk', name: 'Pakistan', countryCode: '+92' },
        { code: 'lk', name: 'Sri Lanka', countryCode: '+94' },
        { code: 'us', name: 'United States', countryCode: '+1' },
        { code: 'uk', name: 'United Kingdom', countryCode: '+44' },
        { code: 'cn', name: 'China', countryCode: '+86' },
        { code: 'jp', name: 'Japan', countryCode: '+81' },
        { code: 'de', name: 'Germany', countryCode: '+49' },
        { code: 'br', name: 'Brazil', countryCode: '+55' },
        { code: 'za', name: 'South Africa', countryCode: '+27' },
        { code: 'ng', name: 'Nigeria', countryCode: '+234' },
        { code: 'ru', name: 'Russia', countryCode: '+7' },
        { code: 'mx', name: 'Mexico', countryCode: '+52' },
        { code: 'it', name: 'Italy', countryCode: '+39' },
        { code: 'es', name: 'Spain', countryCode: '+34' },
        { code: 'kr', name: 'South Korea', countryCode: '+82' },
        { code: 'ae', name: 'United Arab Emirates', countryCode: '+971' },
        { code: 'sg', name: 'Singapore', countryCode: '+65' },
        { code: 'tr', name: 'Turkey', countryCode: '+90' },
        { code: 'id', name: 'Indonesia', countryCode: '+62' },
        { code: 'ph', name: 'Philippines', countryCode: '+63' },
        { code: 'ar', name: 'Argentina', countryCode: '+54' },
        { code: 'cl', name: 'Chile', countryCode: '+56' },
        { code: 'nl', name: 'Netherlands', countryCode: '+31' },
        { code: 'se', name: 'Sweden', countryCode: '+46' },
        { code: 'my', name: 'Malaysia', countryCode: '+60' },
        { code: 'th', name: 'Thailand', countryCode: '+66' },
        { code: 'vn', name: 'Vietnam', countryCode: '+84' },
        { code: 'nz', name: 'New Zealand', countryCode: '+64' },
        { code: 'pl', name: 'Poland', countryCode: '+48' },
        { code: 'ke', name: 'Kenya', countryCode: '+254' },
        { code: 'ua', name: 'Ukraine', countryCode: '+380' },
        { code: 'gr', name: 'Greece', countryCode: '+30' },
        { code: 'ch', name: 'Switzerland', countryCode: '+41' },
        { code: 'no', name: 'Norway', countryCode: '+47' },
        { code: 'dk', name: 'Denmark', countryCode: '+45' },
        { code: 'fi', name: 'Finland', countryCode: '+358' },
        { code: 'be', name: 'Belgium', countryCode: '+32' },
        { code: 'at', name: 'Austria', countryCode: '+43' },
        { code: 'hu', name: 'Hungary', countryCode: '+36' },
        { code: 'cz', name: 'Czech Republic', countryCode: '+420' },
        { code: 'pt', name: 'Portugal', countryCode: '+351' },
        { code: 'ie', name: 'Ireland', countryCode: '+353' },
        { code: 'ro', name: 'Romania', countryCode: '+40' },
        { code: 'bg', name: 'Bulgaria', countryCode: '+359' },
        { code: 'sk', name: 'Slovakia', countryCode: '+421' },
        { code: 'si', name: 'Slovenia', countryCode: '+386' },
        { code: 'hr', name: 'Croatia', countryCode: '+385' },
        { code: 'rs', name: 'Serbia', countryCode: '+381' },
        { code: 'il', name: 'Israel', countryCode: '+972' },
        { code: 'ma', name: 'Morocco', countryCode: '+212' },
        { code: 'dz', name: 'Algeria', countryCode: '+213' },
        { code: 'tn', name: 'Tunisia', countryCode: '+216' },
        { code: 'gh', name: 'Ghana', countryCode: '+233' },
        { code: 'co', name: 'Colombia', countryCode: '+57' },
        { code: 'pe', name: 'Peru', countryCode: '+51' },
        { code: 've', name: 'Venezuela', countryCode: '+58' },
        { code: 'sa', name: 'Saudi Arabia', countryCode: '+966' },
        { code: 'ae', name: 'United Arab Emirates', countryCode: '+971' },
        { code: 'iq', name: 'Iraq', countryCode: '+964' },
        { code: 'jo', name: 'Jordan', countryCode: '+962' },
        { code: 'kw', name: 'Kuwait', countryCode: '+965' },
        { code: 'lb', name: 'Lebanon', countryCode: '+961' },
        { code: 'om', name: 'Oman', countryCode: '+968' },
        { code: 'qa', name: 'Qatar', countryCode: '+974' },
        { code: 'sy', name: 'Syria', countryCode: '+963' },
        { code: 'ye', name: 'Yemen', countryCode: '+967' },
        { code: 'af', name: 'Afghanistan', countryCode: '+93' },
        { code: 'bd', name: 'Bangladesh', countryCode: '+880' },
        { code: 'bt', name: 'Bhutan', countryCode: '+975' },
        { code: 'kh', name: 'Cambodia', countryCode: '+855' },
        { code: 'kz', name: 'Kazakhstan', countryCode: '+7' },
        { code: 'kg', name: 'Kyrgyzstan', countryCode: '+996' },
        { code: 'la', name: 'Laos', countryCode: '+856' },
        { code: 'mm', name: 'Myanmar', countryCode: '+95' },
        { code: 'np', name: 'Nepal', countryCode: '+977' },
        { code: 'kp', name: 'North Korea', countryCode: '+850' },
        { code: 'tw', name: 'Taiwan', countryCode: '+886' },
        { code: 'tj', name: 'Tajikistan', countryCode: '+992' },
        { code: 'tm', name: 'Turkmenistan', countryCode: '+993' },
        { code: 'uz', name: 'Uzbekistan', countryCode: '+998' },
        { code: 'bn', name: 'Brunei', countryCode: '+673' },
        { code: 'mn', name: 'Mongolia', countryCode: '+976' },
        { code: 'bt', name: 'Bhutan', countryCode: '+975' },
        { code: 'kh', name: 'Cambodia', countryCode: '+855' },
        { code: 'mv', name: 'Maldives', countryCode: '+960' },
        { code: 'np', name: 'Nepal', countryCode: '+977' },
        { code: 'sg', name: 'Singapore', countryCode: '+65' },
        { code: 'lk', name: 'Sri Lanka', countryCode: '+94' },
        { code: 'tm', name: 'Turkmenistan', countryCode: '+993' },
        { code: 'uz', name: 'Uzbekistan', countryCode: '+998' },
        { code: 'bn', name: 'Brunei', countryCode: '+673' },
        { code: 'la', name: 'Laos', countryCode: '+856' },
        { code: 'mm', name: 'Myanmar', countryCode: '+95' },
        { code: 'mv', name: 'Maldives', countryCode: '+960' },
        { code: 'np', name: 'Nepal', countryCode: '+977' },
        { code: 'bt', name: 'Bhutan', countryCode: '+975' },
        { code: 'kh', name: 'Cambodia', countryCode: '+855' },
        { code: 'pg', name: 'Papua New Guinea', countryCode: '+675' },
        { code: 'fj', name: 'Fiji', countryCode: '+679' },
        { code: 'nc', name: 'New Caledonia', countryCode: '+687' },
        { code: 'pf', name: 'French Polynesia', countryCode: '+689' },
        { code: 'as', name: 'American Samoa', countryCode: '+1 684' },
        { code: 'gu', name: 'Guam', countryCode: '+1 671' },
        { code: 'mp', name: 'Northern Mariana Islands', countryCode: '+1 670' },
        { code: 'fm', name: 'Micronesia', countryCode: '+691' },
        { code: 'mh', name: 'Marshall Islands', countryCode: '+692' },
        { code: 'pw', name: 'Palau', countryCode: '+680' },
        { code: 'sb', name: 'Solomon Islands', countryCode: '+677' },
        { code: 'vu', name: 'Vanuatu', countryCode: '+678' },
        { code: 'ck', name: 'Cook Islands', countryCode: '+682' },
        { code: 'to', name: 'Tonga', countryCode: '+676' },
        { code: 'tv', name: 'Tuvalu', countryCode: '+688' },
        { code: 'ws', name: 'Samoa', countryCode: '+685' },
    ];


    useEffect(() => {
        setLoginForm({
            ...loginForm,
            country: 'Saudi Arabia',
            countryCode: '+966',
        });
    }, [setLoginForm]);
    //validation
    const validate = (values) => {
        const errors = {};
        const country = countries.find(country => country.name === values.country);

        if (!values.country) {
            errors.country = 'Country is required!';
        }
        if (!values.phone) {
            errors.phone = 'Mobile Number is required!';
        } else if (country) {
            const phoneRegex = getPhoneNumberRegex(country.countryCode);
            if (!phoneRegex.test(values.phone)) {
                errors.phone = 'This is not a valid mobile number format for the selected country!';
            }
        } else {
            errors.phone = 'Invalid country selected!';
        }

        return errors;
    };
    const getPhoneNumberRegex = (countryCode) => {
        const regexMap = {

            '+966': /^5\d{8}$/,        // Saudi Arabia
            '+91': /^[6-9]\d{9}$/,      // India
            '+20': /^\d{10}$/,          // Egypt
            '+61': /^(\+61|0)[2-478](\d{8})$/, // Australia
            '+1': /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/, // Canada
            '+33': /^\d{10}$/,          // France
            '+92': /^[3]\d{9}$/,        // Pakistan
            '+94': /^[0]\d{9}$/,        // Sri Lanka
        };

        return regexMap[countryCode] || /^.+$/;
    };

    const handleCountryChange = (event) => {
        const selectedCountry = countries.find(country => country.code === event.target.value);
        if (selectedCountry) {
            setLoginForm({
                ...loginForm,
                country: selectedCountry.name,
                countryCode: selectedCountry.countryCode
            });
        }
    };

    const getCountryCode = (selectedCountry) => {
        const country = countries.find((c) => c.name === selectedCountry);
        return country ? country.countryCode : '';
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = validate(loginForm);

        if (Object.keys(errors).length === 0) {
            setIsLoading(true);
            setError(null);
            try {
                const formData = new FormData();
                formData.append('country', loginForm.country);
                formData.append('phone', loginForm.phone);

                const response = await fetch('https://zwarra.biztechnosys.com/api/login', {
                    method: 'POST',
                    headers: {
                        'Cookie': 'zwarra_session=q3ei4VMHZzytvKDUsxsnbXAx1tH0DqgsfnG4wLmR'
                    },
                    body: formData,
                });

                if (!response.ok) {
                    if (response.status === 401) {
                        throw new Error('Mobile number is not registered');
                    } else {
                        throw new Error('Login failed');
                    }
                }

                const data = await response.json();
                console.log("Logged in successfully!", data);
                window.location.reload();

                if (data.message === 'Login successful' && data.token && data.username) {
                    setShow(false);

                    sessionStorage.setItem('isAuthenticated', JSON.stringify(true));
                    sessionStorage.setItem('username', JSON.stringify(data.username));
                    setIsAuthenticated(true);
                    setUsername(data.username);


                    const currentModal = window.bootstrap.Modal.getInstance(
                        document.getElementById("Loginmodal")
                    );
                    currentModal.hide();

                } else {
                    throw new Error('Login failed');
                }

            } catch (error) {
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        } else {
            setFormErrors(errors);
        }
    };

    const handleMobileNumberChange = (event) => {
        const { value } = event.target;
        setLoginForm({ ...loginForm, phone: value });

        if (formErrors.phone) {
            const country = countries.find(country => country.name === loginForm.country);

            if (country) {
                const phoneRegex = getPhoneNumberRegex(country.countryCode);
                if (phoneRegex.test(value)) {
                    setFormErrors({ ...formErrors, phone: undefined });
                }
            }
        }
    };

    // Effect to clean up the modal backdrop
    useEffect(() => {
        if (!show) {
            const backdrops = document.querySelectorAll('.modal-backdrop');
            backdrops.forEach(backdrop => backdrop.parentNode.removeChild(backdrop));
        }
    }, [show]);

    const onSignup = () => {
        const nextModal = new window.bootstrap.Modal(document.getElementById('signup'));
        const currentModal = window.bootstrap.Modal.getInstance(document.getElementById('Loginmodal'));
        currentModal.hide();
        nextModal.show();
    }
    return (
        <>
            <div className={`zw_popup ${show ? 'show' : ''}`}>
                <div className={`modal fade zw_captch_pop ${show ? 'show' : ''}`} id="Loginmodal" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden={!show}>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className='btn-space'>
                                    <button type="button" className="sps-dialog-close regv2back" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShow(false)}>
                                        <i className="icon-close"></i>
                                    </button>
                                </div>
                                <div className="card card-primary-light card-no-border last-child m-auto">
                                    <div className="login-brd">
                                        <form onSubmit={handleSubmit}>
                                            <div className="input-group input-group-vert mb20">
                                                <label className='zw_text_color poppins-regular zw_32'>Country</label>
                                                <div className="form-group zw_form_group">
                                                    <div className='zw_form_control zw_secondary poppins-regular zw_16'>
                                                        <div>
                                                            <select
                                                                id="countrycode"
                                                                name="countrycode"
                                                                className="input-no-border poppins-regular zw_22 zw_secondary w-100 px-4 py-2"
                                                                style={{ border: 'none', borderRadius: '4px' }}
                                                                value={countries.find(country => country.name === loginForm.country)?.code || ''}
                                                                onChange={handleCountryChange}
                                                            >
                                                                {countries.map((country) => (
                                                                    <option key={country.code} value={country.code}>
                                                                        {country.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                {formErrors.country && <div style={{ color: 'red' }}>{formErrors.country}</div>}
                                                <div className="form-group zw_form_group">
                                                    <div className='zw_country_sec zw_border_radius zw_light_bg'>
                                                        {loginForm.country && (
                                                            <img
                                                                className="img-ccode form-img zw_country_flag"
                                                                src={`https://sanar-assets.com/flags/${countries.find(country => country.name === loginForm.country)?.code}_64.png`}
                                                                alt="Country Flags"
                                                            />
                                                        )}
                                                        <span className="cuntry-plholder poppins-regular zw_22">{getCountryCode(loginForm.country)}</span>
                                                    </div>
                                                    <input
                                                        type='text'
                                                        className='zw_form_control poppins-regular zw_20 zw_secondary py-2'
                                                        placeholder='Number'
                                                        style={{ width: "100%", paddingLeft: "110px", height: "46px" }}
                                                        name="phone"
                                                        value={loginForm.phone}
                                                        onChange={handleMobileNumberChange}
                                                    />
                                                    {formErrors.phone && <div style={{ color: 'red' }}>{formErrors.phone}</div>}
                                                </div>
                                            </div>
                                            <div className="form-group f-size12 poppins-regular zw_16">
                                                By clicking continue you agree to our&nbsp;
                                                <Link to="/termsandcondition" className="link" onClick={handleClose}>Terms &amp; Conditions</Link>{' '}and{' '}
                                                <Link to="/privacypolicy" className="link" onClick={handleClose}>Privacy Policy</Link>
                                            </div>
                                            <button type="submit" className="btn-primary btn-lg btn-block poppins-regular zw_bg_gradient zw_btn_18 zw_sbmtbtn_radius">
                                                {isLoading ? 'Logging in...' : 'Login'}
                                            </button>
                                            <p className='poppins-regular zw_20 zw_title_color' style={{ textAlign: "center" }}>
                                                Don't have an account? <Link className='signup_link' onClick={onSignup} ><span style={{ color: '#602D8A' }}>Sign Up</span></Link>
                                            </p>
                                            {error && <div style={{ color: 'red', textAlign: 'center', marginTop: '10px' }}>{error}</div>}
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`modal-backdrop fade ${show ? 'show' : ''}`}></div>
            </div>
        </>
    );
}

export default LoginPopup;
