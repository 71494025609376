import React, {useContext} from "react";
import "../../../Style/LifeStyle.css";
import Navbar from "../../Layout/Navbar";
import Footer from "../../Layout/Footer";
import { Link } from "react-router-dom";
import { Context } from "../../../Context";

function LifeStyle() {
    const {username}  = useContext(Context);

    return (
        <div>
            <div className="patient-profile-view-fzk">
                <div className="auto-group-rktc-ykY">
                    <div className="group-182-VD6">
                        <Navbar />
                    </div>
                    <Link to="/profile">
                        <div className="group-1261154838-vDr">
                            <img
                                className="group-1261154072-EEY"
                                src="./images/group-1261154072-2y2.png"
                                alt="group-1261154072-2y2"
                            />
                            <p className="back-w8x m-0">Back</p>
                        </div>
                    </Link>
                    <div className="auto-group-rv4y-Tsz">
                        <div className="auto-group-ycng-R48">
                            <div className="frame-1261154254-MiU">
                                <div className="auto-group-twhi-swi">
                                    <img
                                        className="group-1261154953-p6G"
                                        src="./images/group-1261154953.png"
                                        alt="group-1261154953"
                                    />
                                </div>
                                <div class="noha-abdullah-jU8">{username}</div>
                            </div>
                            <div className="auto-group-4gsv-EQt">
                                <div className="group-1261154801-aDr">
                                    <div className="group-1261154793-GsN">
                                        <Link to="/records">
                                            <div className="auto-group-j5yn-bue">
                                                <img className="vector-vwv" src="./images/records-icons.png" alt="vector-Czp" />
                                               
                                                <div className="records-qoz">Records</div>
                                            </div>
                                        </Link>
                                        <div className="auto-group-11me-xtc">
                                            <div className="group-1261154888-JBn">
                                                <img className="icon-eWY" src="./images/icon.png" alt="icon" />
                                                <div className="personal-nMr">Personal</div>
                                            </div>
                                        </div>
                                        <Link to="/medical">
                                            <div className="auto-group-n8ha-Jb6">
                                                <img
                                                    className="group-1261154885-qb2"
                                                    src="./images/group-1261154885.png"
                                                    alt="group-1261154885"
                                                />
                                                <div className="medical-AdJ">Medical</div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="group-1261154884-tpC">
                                        <div className="group-1261154883-DrU">
                                            <img className="vector-Mhn" src="./images/vector-L4U.png" alt="vector-L4U" />
                                            <div className="life-style-egt">Life Style</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="my-zwrrar-pUt">My Zwrrar</div>
                                <div className="group-1261154808-Yfn">
                                    <div className="rectangle-39639-5vc"></div>
                                    <div className="my-programs-Qhz">My Programs</div>
                                    <img
                                        className="group-1261154897-hwz"
                                        src="./images/group-1261154897.png"
                                        alt="group-1261154897"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="auto-group-qnds-Ctk">
                            <div className="view-patient-KiU poppins-semibold zw_16 zw_text_color">
                                View patient
                            </div>
                            <div className="auto-group-qnds-Ctkk">
                                <form>
                                    <div className="zw_16 zw_text_color poppins-regular mb-2">
                                        Smoking Habits
                                    </div>
                                    <div>
                                        <select className="poppins-regular px-4 zw_text_color life-style-selectfi">
                                            <option>Select</option>
                                            <option>10 Days</option>
                                            <option>1-2 Day</option>
                                            <option>2-3 Days</option>
                                            <option>I used to,but i have quit</option>
                                            <option>Don,t smoke</option>
                                        </select>
                                    </div>

                                    <div className="my-5">
                                        <div className="zw_16 zw_text_color poppins-regular mb-2">
                                            Activity Level
                                        </div>
                                        <select className="poppins-regular px-4 zw_text_color life-style-selectfi">
                                            <option>Select</option>
                                        </select>
                                    </div>
                                    <div className="my-5">
                                        <div className="zw_16 zw_text_color poppins-regular mb-2">
                                            Food Preference
                                        </div>
                                        <select className="poppins-regular px-4 zw_text_color life-style-selectfi">
                                            <option>Select</option>
                                        </select>
                                    </div>
                                    <div className="my-5">
                                        <div className="zw_16 zw_text_color poppins-regular mb-2">
                                            Occupation
                                        </div>
                                        <select className="poppins-regular px-4 zw_text_color life-style-selectfi">
                                            <option>Select</option>
                                        </select>
                                    </div>

                                    <div className="d-flex justify-content-end">
                                        <button className="poppins-semibold zw_16 zw_text_fff zw_bg_gradient border-0 rounded-3" style={{ height: '53px', width: '39%' }}>Save</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default LifeStyle;
