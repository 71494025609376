import React, { useState, useEffect, useContext } from 'react';
import '../../Style/VaccinationList.css';
import { Link ,useNavigate} from 'react-router-dom';
import Navbar from "../Layout/Navbar";
import Footer from "../Layout/Footer";

// images
import vaccine2mon from "../../images/vaccine-2mon.png";

import vaccine4mon from "../../images/vaccine-4mon.png";
import vaccine6mon from "../../images/vaccine-6mon.png";
import vaccine9mon from "../../images/vaccine-9mon.png";
import vaccine1yrs from "../../images/vaccine-1yrs.png";
import vaccine2yrs from "../../images/vaccine-2yrs.png";
import vaccine18mon from "../../images/vaccine-18mon.png";
import close from "../../images/close.svg"

import { Context } from '../../Context';
import MapLocationPop from '../MapLocationPop';
function VaccinationList() {

    // const VaccinationData = [
    //     {
    //         headingName: 'Children’s Vaccination ( Age: 2 Month )',
    //         description: 'The vaccination team will provide two-month-old children immunizations against (IPV, DTaP, Hepatitis B, Hib, Pneumococcal Conjugate, (PVC), and Rota).',
    //         subDescription: 'Includes Vaccination material, dgloves, alcohol wipes, masks and sterilization for the medical team, Vaccination record will be updated and stamped',
    //         img: vaccine2mon,
    //     },
    //     {
    //         headingName: 'Children’s Vaccination ( Age: 4 Month )',
    //         description: 'Vaccine: • IPV • DtaP • Hepatitis B • Hib • Pneumococcal Conjugate (PVC) • Rota Vaccine certificate will be updated by stamping',
    //         subDescription: 'Includes Vaccination material, dgloves, alcohol wipes, masks and sterilization for the medical team, Vaccination record will be updated and stamped',
    //         img: vaccine4mon,
    //     },
    //     {
    //         headingName: 'Children’s Vaccination ( Age: 6 Month )',
    //         description: "The vaccination team will provide 6-month-old children immunizations against OPV IPV DtaP Hepatitis B Hib Pneumococcal Conjugate (PVC) Vaccine certificate will be updated by stamping",
    //         subDescription: 'Includes Vaccination material, dgloves, alcohol wipes, masks and sterilization for the medical team, يتم تحديث وختم سجل التطعيمات',
    //         img: vaccine6mon,
    //     },
    //     {
    //         headingName: 'Children’s Vaccination ( Age: 9 Month )',
    //         description: 'Vaccine: • Measles • Meningococcal • Conjugate quadrivalent (MCV4) Vaccine certificate will be updated by stamping',
    //         subDescription: 'Includes Vaccination material, dgloves, alcohol wipes, masks and sterilization for the medical team, Vaccination record will be updated and stamped',
    //         img: vaccine9mon,
    //     },
    //     {
    //         headingName: 'Children’s Vaccination (Age: 1 Year)',
    //         description: 'The vaccination team will provide 1 Year old children immunizations against (PCV-OPVMCV4-MMR ).',
    //         subDescription: 'Includes Vaccination material, dgloves, alcohol wipes, masks and sterilization for the medical team, Vaccination record will be updated and stamped',
    //         img: vaccine1yrs,
    //     },
    //     {
    //         headingName: 'Children’s Vaccination ( Age: 18 Month )',
    //         description: 'The vaccination team will provide 18 month old children immunizations against (DTaPHIB-OPV-HepA-Varicella-MMR ).',
    //         subDescription: 'Includes Vaccination material, dgloves, alcohol wipes, masks and sterilization for the medical team, Vaccination record will be updated and stamped',
    //         img: vaccine18mon,
    //     },
    //     {
    //         headingName: 'Children’s Vaccination ( Age: 2 Years )',
    //         description: 'The vaccination team will provide 2 years old children immunizations against (HepA ).',
    //         subDescription: 'Includes Vaccination material, dgloves, alcohol wipes, masks and sterilization for the medical team, Vaccination record will be updated and stamped',
    //         img: vaccine2yrs,
    //     }
    // ]
    const navigate = useNavigate();

    const [selectedTest, setSelectedTest] = useState([]);
    // for serch fillter
    const [query, setQuery] = useState('');
    // const [filteredData, setFilteredData] = useState(VaccinationData);
    // const [count, setCount] = useState(Array(filteredData.length).fill(0));
    // console.log(count)

    const handleSearchInputChange = (e) => {
        const value = e.target.value;
        setQuery(value);

        // const filtered = VaccinationData.filter((item) =>
        //     item.Enname.toLowerCase().includes(value.toLowerCase())
        // );
        // setFilteredData(filtered);
    };


    const handleToggleSelect = (item) => {
        if (selectedTest.includes(item)) {
            setSelectedTest(prevSelected => prevSelected.filter(selected => selected !== item));
        } else {
            setSelectedTest(prevSelected => [...prevSelected, item]);
        }
    }

   

    const [locationName, setLocationName] = useState('');
    const { show, setShow } = useContext(Context)

    // const handleTestSelection = (testTitle) => {
    //     setSelectedTest((prevTests) => {
    //       if (prevTests.includes(testTitle)) {
    //         // Remove the test if it's already selected
    //         return prevTests.filter((test) => test !== testTitle);
    //       } else {
    //         // Add the test if it's not already selected
    //         return [...prevTests, testTitle];
    //       }
    //     });
    //   };
    

    const { appointmentData, updateAppointmentData, addressList } =
    useContext(Context);
    const [zoneData, setZoneData] = useState([]);
    const fetchZoneData = async (latitude, longitude) => {
        try {
          const response = await fetch(
            `https://zwarra.biztechnosys.com/api/fetchZoneData/${latitude}/${longitude}`,
            {
              headers: {
                "Content-Type": "application/json",
                Cookie: "zwarra_session=8svaEnKXoPHya4NjfgtmI4XABhWqWjVpkmz53q2L",
              },
            }
          );
          const data = await response.json();
          setZoneData(data); // Set the fetched zone data to state
          console.log("Zone Data:", data); // Log the zone data
        } catch (error) {
          console.error("Error fetching zone data:", error);
        }
      };
      const [VaccinationData, setVaccinationData] = useState([]);
        useEffect(() => {
            fetch(
            "https://zwarra.biztechnosys.com/api/subservices?id=10&servicetype=single"
            )
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                setVaccinationData(data);
            });
        }, []);


    const showLocation = (loc) => {
        setLocationName(loc);
        updateAppointmentData({ Address: loc });
        const latitude = 45.07187238118124;
        const longitude = 26.286879877969852;
        fetchZoneData(latitude, longitude);
      };
      const handlenext = async (e) => {
        e.preventDefault();
        if (selectedTest.length === 0) return; // Prevent continuation without selection
        console.log("Step 2 data", appointmentData);
        const subservicesJson = JSON.stringify(selectedTest.map(test => (test.Enname)));
        updateAppointmentData({ Subservices: subservicesJson });
        navigate("/Vaccinationcenter");
      };
    return (
        <div>
            <Navbar />
            <div className="vaccination" style={{ marginTop: "50px" }}>

                <div className="container-17">
                    <div className="group-1261154093">
                        <Link to={'/'}>
                            <div className="d-flex">
                                <img className="group-1261154072" src="/images/Group 1261154072.png" alt=''/>
                                <span className="back poppins-medium zw_18 zw_black">
                                    Back
                                </span>
                            </div>
                        </Link>
                        <div className="line_indicator_container">
                            <div className="each_line_indicator active">
                            </div>
                            <div className="each_line_indicator">
                            </div>
                            <div className="each_line_indicator">
                            </div>
                            <div className="each_line_indicator">
                            </div>
                        </div>
                    </div>
                    <div className="vaccination-list poppins-semibold zw_34 zw_text_color">
                        Vaccination List
                    </div>
                    <div className="container-13 gap-5">
                    <div className="col-5  frames-37119 p-4">
                    <div className='align-self-center d-flex select-location-hover ' onClick={() => setShow(true)}>
                      <img className="grouped-1" src="/images/location.png" alt='' />
                      <div className="ms-3 poppins-semibold zw_14 zw_title_color" >Selected Location</div>
                    </div>
                    <div className="lined-1"></div>
                    <div>
                      <span className="poppins-semibold zw_14 zw_title_color">{appointmentData.Address}</span>
                      {/* <img className="vector-19" src="/images/downarrow.png" alt='' /> */}
                    </div>
                  </div>
                        <div class="search-container">
                            <form style={{ display: 'flex' }}>
                                <input
                                    style={{ width: '38rem' }}
                                    type="text"
                                    className="search-input zw_16 poppins-regular"
                                    placeholder="Search "
                                    value={query}
                                    onChange={handleSearchInputChange}
                                />
                                <button class="search-button zw_bgwhite">
                                    <i class="icon-search zw_black "></i>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="container-4">
                    <div>
                        {VaccinationData.length > 0 ? (
                            VaccinationData?.map((item, index) => (
                                <div className='children-vaccination-card' key={item?.Enname}>
                                    <div className='vaccination-box'>
                                        {/* {console.log("data: " ,selectedTest[index], index)} */}
                                        <div  className={`select_box ${selectedTest.includes(item) ? 'select-box-bg' : ''}`}
                                            onClick={() => handleToggleSelect(item)}></div>
                                        <div className='children-vaccination'>
                                            <img  src={`https://zwarra.biztechnosys.com/${item?.Logo}`} className='vaccination-img' alt="Logo"  />
                                            <div>
                                                <div className='childrens-vaccination-age-2-month poppins-bold zw_24 zw_text_color' style={{ marginLeft: 0, marginTop: 0 }}>{item?.Enname}</div>
                                                <div className='vaccination-subtext poppins-regular zw_16 zw_secondary'>{item?.Endescription}</div>
                                            </div>
                                        </div>
                                        <div className='vaccination-details'>
                                            <span className="poppins-regular zw_16 zw_title_color">
                                                {item?.Ardescription}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className='no-data-found poppins-semibold zw_18 zw_text_color'>
                                No data found
                            </div>
                        )}
                    </div>

                    

                    <div className='ms-4'>
                        <div className="selected-test poppins-semibold zw_16 zw_text_color">
                            Selected test
                        </div>
                        <div className="container-11">
                            {selectedTest?.map((item, index) => (
                                <div className="frame-1261154252">
                                    <div className="group-1261154161">
                                        <span className="poppins-regular zw_12 zw_title_color">
                                            {item?.Enname}
                                        </span>
                                    </div>
                                    <button className="component-1" onClick={() => handleToggleSelect(item)}>
                                        <img src={close} style={{ height: "100%", width: "100%" }} alt='' />
                                    </button>
                                </div>
                            ))}
                           
                            <Link to={`${selectedTest?.length !== 0 ? '/vaccinationcenter' : ''}`}>
                                <button  onClick={handlenext} className={`frame-37121 poppins-regular zw_14 zw_text_fff ${selectedTest?.length === 0 ? 'disabled' : ''}`}>
                                    Continue
                                </button>
                            </Link>
                        </div>
                       
                    </div>
                </div>
                {show ? <MapLocationPop path={"samePage"} locName={showLocation} /> : ""}
            </div>
            <Footer />
        </div>
    )
}

export default VaccinationList
