import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import "../../Style/login_popup.css";
import { Context } from "../../Context";

function Signup() {
  const { signupFormData, updateSignupFormData } = useContext(Context);
  const [formErrors, setFormErrors] = useState({});
  const [flag, setFlag] = useState("");
  const [code, setCode] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const countries = [
    { code: "in", name: "India", countryCode: "+91" },
    { code: "sa", name: "Saudi Arabia", countryCode: "+966" },
    { code: "eg", name: "Egypt", countryCode: "+20" },
    { code: "au", name: "Australia", countryCode: "+61" },
    { code: "cn", name: "Canada", countryCode: "+1" },
    { code: "fr", name: "France", countryCode: "+33" },
    { code: "pk", name: "Pakistan", countryCode: "+92" },
    { code: "sl", name: "Sri Lanka", countryCode: "+94" },
  ];
  

  const phoneNumberPatterns = {
    "+91": /^[0-9]{10}$/,        // India
    "+966": /^[0-9]{9}$/,        // Saudi Arabia
    "+20": /^[0-9]{10}$/,        // Egypt
    "+61": /^[0-9]{9}$/,         // Australia
    "+1": /^[0-9]{10}$/,         // Canada
    "+33": /^[0-9]{9}$/,         // France
    "+92": /^[0-9]{11}$/,        // Pakistan
    "+94": /^[0-9]{10}$/,        // Sri Lanka
  };


  const validate = (values) => {
    const errors = {};
    // const mobileNumberRegex =
    //   /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

    if (!values.Firstname) {
      errors.Firstname = "First Name is required!";
    }
    if (!values.Lastname) {
      errors.Lastname = "Last Name is required!";
    }
    if (!values.Phone) {
      errors.Phone = "Mobile Number is required!";
    // } else if (!mobileNumberRegex.test(values.Phone)) {
    //   errors.Phone = "This is not a valid mobile Number format!";
    }else {
      const phonePattern = phoneNumberPatterns[code] || /^[0-9]+$/;
      if (!phonePattern.test(values.Phone)) {
        errors.Phone = "This is not a valid mobile number format!";
      }
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validate(signupFormData);
    if (isValid) {
      const nextModal = new window.bootstrap.Modal(
        document.getElementById("accountsetup_details")
      );
      const currentModal = window.bootstrap.Modal.getInstance(
        document.getElementById("signup")
      );
      updateSignupFormData(signupFormData);
      console.log("Step 1 data", signupFormData);
      currentModal.hide();
      nextModal.show();



      
      // Clear form fields after successful submission
    //   updateSignupFormData({
    //     Firstname: "",
    //     Lastname: "",
    //     Phone: "",
    //   });
    //   setFlag("");
    //   setCode("");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateSignupFormData({
      ...signupFormData,
      [name]: value,
    });


    // Clear error for the specific field if it is valid
    const newErrors = { ...formErrors };
    if (name === 'Phone') {
        // const mobileNumberRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        const phonePattern = phoneNumberPatterns[code] || /^[0-9]+$/;
        if (!value) {
            newErrors.Phone = 'Mobile Number is required!';
        } else if (!phonePattern.test(value)) {
            newErrors.Phone = 'This is not a valid mobile Number format for selected country!';
        } else {
            delete newErrors.Phone;
        }
    } else {
        if (!value) {
            newErrors[name] = `${name === 'Firstname' ? 'First Name' : 'Last Name'} is required!`;
        } else {
            delete newErrors[name];
        }
    }

    setFormErrors(newErrors);
  };

  return (
    <div className="zw_popup">
      <div
        className="modal fade"
        id="signup"
        role="dialog"
        data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
          <div class="modal-header border-0">
          <button type="button" className="sps-dialog-close  regv2back" data-bs-dismiss="modal" aria-label="Close">
                                            <i className="icon-close"></i>
                                        </button>
                                        </div>
            <div className="modal-body">
           
              <div className="card card-primary-light card-no-border last-child mt-4">
                <div className="login-brd">
                  <form onSubmit={handleSubmit}>
                    <div className="input-group input-group-vert mb20">
                      <div className="form-group zw_form_group mb-0">
                        <input
                          type="text"
                          className="w-100 py-3 px-2 zw_form_control zw_secondary poppins-regular zw_16"
                          placeholder="First Name"
                          name="Firstname"
                          value={signupFormData.Firstname}
                          onChange={handleChange}
                        ></input>
                      </div>
                      {formErrors.Firstname && (
                        <span
                          className="sa-error-message"
                          style={{ color: "red" }}
                        >
                          {formErrors.Firstname}
                        </span>
                      )}
                      <div className="form-group zw_form_group mb-0 mt-5">
                        <input
                          type="text"
                          className="w-100 py-3 px-2 zw_form_control zw_secondary poppins-regular zw_16"
                          placeholder="Last Name"
                          name="Lastname"
                          value={signupFormData.Lastname}
                          onChange={handleChange}
                        ></input>
                      </div>
                      {formErrors.Lastname && (
                        <span
                          className="sa-error-message"
                          style={{ color: "red" }}
                        >
                          {formErrors.Lastname}
                        </span>
                      )}

                      <div className="form-group mb-0 mt-5">
                        <div className="row zw_form_control m-0 ">
                          <div
                            className="col-md-3 zw_light_bg zw_border_radius"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div className="zw_secondary poppins-regular zw_16">
                              <div
                                onClick={() => setDropdownOpen(!dropdownOpen)}
                              >
                                {flag === "" ? (
                                  <>
                                    <img
                                      src={`https://sanar-assets.com/flags/sa_64.png`}
                                      alt="Country Flags"
                                      height={20}
                                      width={20}
                                    />{" "}
                                    +966
                                  </>
                                ) : (
                                  <>
                                    <img
                                      src={`https://sanar-assets.com/flags/${flag}_64.png`}
                                      alt="Country Flags"
                                      height={20}
                                      width={20}
                                    />{" "}
                                    {code}
                                  </>
                                )}
                              </div>
                              <div
                                className="dpcontent"
                                style={{
                                  position: "absolute",
                                  backgroundColor: "#f7e9ec",
                                  padding: "10%",
                                  width: "100%",
                                  maxHeight: dropdownOpen ? "250px" : "0",
                                  zIndex: "10",
                                  overflow: "auto",
                                  cursor: "pointer",
                                  display: dropdownOpen ? "block" : "none",
                                }}
                              >
                                {countries.map((item, index) => (
                                  <div
                                    className=""
                                    key={index}
                                    onClick={() => {
                                      setCode(item.countryCode);
                                      setFlag(item.code);
                                      setDropdownOpen(false);
                                    }}
                                  >
                                    <img
                                      src={`https://sanar-assets.com/flags/${item.code}_64.png`}
                                      alt="Country Flags"
                                      height={20}
                                      width={20}
                                    />{" "}
                                    {item.countryCode}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-9">
                            <input
                              type="tel"
                              className={`zw_secondary poppins-regular zw_16 py-3 w-100 zw_border_none err-border ${
                                formErrors.Phone && "form-error"
                              }`}
                              id="mobile_number"
                              name="Phone"
                              placeholder="Phone Number"
                              value={signupFormData.Phone}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                      {formErrors.Phone && (
                        <span
                          className="sa-error-message"
                          style={{ color: "red" }}
                        >
                          {formErrors.Phone}
                        </span>
                      )}
                    </div>
                    <div className="form-group f-size12 poppins-regular zw_16">
                      By clicking continue you agree to our&nbsp;
                      <Link to="/termsandcondition" className="link">
                        Terms &amp; Conditions
                      </Link>
                      {""} and{""}{" "}
                      <Link to="/privacypolicy" className="link">
                        Privacy Policy
                      </Link>
                    </div>
                    <button
                      type="submit"
                      className="zw_text_fff mt-5 poppins-medium zw_30 zw_bg_gradient zw_btn_18 zw_border_none next-btn-signup"
                    >
                      Next
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ====================================  done========================== */}
    </div>
  );
}

export default Signup;
