import { Button, Modal } from "react-bootstrap";
import React, { useRef, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Link, useNavigate } from "react-router-dom";
import "../../../Style/Ourfeaturetestspopup.css";
import "../../../Style/Addrecord.css";
import "../../../Style/Addpatient.css";
import ReactFlagsSelect from "react-flags-select";
const Addrecord = () => {
  const [showFirstModal, setShowFirstModal] = useState(false);
  const [showSecondModal, setShowSecondModal] = useState(false);
  const navigate = useNavigate();
  const modalRef = useRef(null);
  const addPatientModalRef = useRef(null);
  const modalInstanceRef = useRef(null);
  const addPatientModalInstanceRef = useRef(null);
  const [patientData, setPatientData] = useState([]);
  const [selected, setSelected] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]); // New state for uploaded files

  const [errors, setErrors] = useState({}); // For form validation errors

  
  useEffect(() => {
    if (modalRef.current) {
      modalInstanceRef.current = new window.bootstrap.Modal(modalRef.current);
    }

    if (addPatientModalRef.current) {
      addPatientModalInstanceRef.current = new window.bootstrap.Modal(
        addPatientModalRef.current
      );
    }

    fetchPatientData(); // Fetch initial patient data on component mount
  }, []);

  const fetchPatientData = async () => {
    try {
      const response = await fetch(
        "https://zwarra.biztechnosys.com/api/getpatients",
        {
          headers: {
            Cookie: "zwarra_session=ehKPVgnMkHItOwPyeIxWiVODQtDFbQSmkUJv8UsJ", // Adjust headers as per your API requirements
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch patient data");
      }
      const data = await response.json();
      setPatientData(data); // Update patient data state with fetched data
    } catch (error) {
      console.error("Error fetching patient data:", error);
    }
  };


  const handleFileUpload = (event) => {
    const files = Array.from(event.target.files);
    const fileObjects = files.map((file) => ({
      file,
      url: URL.createObjectURL(file), // Generate a URL for the file
    }));
    setUploadedFiles((prevFiles) => [...prevFiles, ...fileObjects]);
  };
  const handleRemoveFile = (index) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };


  const handleAddPatientClick = () => {
    if (modalInstanceRef.current) {
      modalInstanceRef.current.hide();
    }
    if (addPatientModalInstanceRef.current) {
      addPatientModalInstanceRef.current.show();
    }
  };

  const validateForm = (formData) => {
    const newErrors = {};
    if (!formData.get("Firstname")) newErrors.Firstname = "First name is required";
    if (!formData.get("Lastname")) newErrors.Lastname = "Last name is required";
    if (!selected) newErrors.Country = "Country is required";
    if (!formData.get("Nationalid")) newErrors.Nationalid = "National ID is required";
    if (!formData.get("Gender")) newErrors.Gender = "Gender is required";
    if (!formData.get("Dob")) newErrors.Dob = "Date of Birth is required";
    if (!formData.get("Bloodgroup")) newErrors.Bloodgroup = "Blood group is required";
    if (!formData.get("Phone")) newErrors.Phone = "Phone number is required";
    if (!formData.get("Relationship")) newErrors.Relationship = "Relationship is required";
    if (!formData.get("InsuranceCompany")) newErrors.InsuranceCompany = "InsuranceCompany is required";
    if (!formData.get("InsuranceAccNo")) newErrors.InsuranceAccNo = "InsuranceAccNo is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Clear specific error based on input change
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "", // Clear error for the changed field
    }));
  };

  const handleCountrySelect = (code) => {
    setSelected(code);

    // Clear the country error when a country is selected
    setErrors((prevErrors) => ({
      ...prevErrors,
      Country: "",
    }));
  };

  const handleSubmitPatient = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    // Clear all errors before validating
    setErrors({});
    if (!validateForm(formData)) return; // Stop submission if form is invalid
    try {
      const response = await fetch(
        "https://zwarra.biztechnosys.com/api/registerpatient",
        {
          method: "POST",
          headers: {
            Cookie: "zwarra_session=ehKPVgnMkHItOwPyeIxWiVODQtDFbQSmkUJv8UsJ", // Adjust headers as per your API requirements
          },
          body: formData,
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        console.error("Error response data:", errorData);
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Success:", data);
      setPatientData([...patientData, data.patient_registration]); // Add the new patient to the list
      if (addPatientModalInstanceRef.current) {
        addPatientModalInstanceRef.current.hide();

      }
    } catch (error) {
      console.error("Error:", error);
    }
    setShowSecondModal(false)
  };
  const handleSecondModalClose = () => setShowSecondModal(false);
  const handleFirstModalClose = () => setShowFirstModal(false);
  const handleFirstModalShow = () => setShowFirstModal(true);
  const handleSecondModalShow = () => setShowSecondModal(true);

  return (
    <div className="App">
      <div
        className="group-1261154834-4fN"
        variant="primary"
        onClick={handleFirstModalShow}
        style={{ cursor: "pointer" }}
      >
        <div className="frame-1261154256-z3E">
          <img
            className="component-2-LMz"
            src="/images/component-2.png"
            alt="component-2"
          />

          <div className="poppins-regular zw_16 zw_text_fff">Add Patient</div>
        </div>
      </div>

      <Modal
        id="addpati"
        className="addpatie"
        show={showFirstModal}
        onHide={handleFirstModalClose}
      >
        <Modal.Header closeButton className="border-0">
          <div className="mx-5 pt-4 zw_text_color poppins-semibold zw_16">
            Add Record
          </div>
        </Modal.Header>
        <Modal.Body>
          {/* <p>This is the first popup.</p> */}
          <div className="add-patient-oKS" style={{ border: "none" }}>
            <div className="group-1261154829-vzp">
              {/* <div >Add Record</div> */}

              <div className="group-1261154828-VwS" style={{ height: "auto" }}>
                <p className="select-patient-ybi">Select patient</p>

                <div className="auto-group-wde4-Wbe">
                  <div className="container">
                    <div
                      className="container-flui "
                      style={{ marginTop: "20px" }}
                    >
                      <div className="row row-cols-lg-3 row-cols-md-2">
                        {/* Display existing patients */}
                        {patientData.map((patient) => (
                          <div key={patient.id} className=" d-flex">
                            <div
                              className="card my-2 border-0 text-center"
                              style={{
                                width: "40rem",
                                backgroundColor: "#AF22451A",
                              }}
                            >
                              <div>
                                <h3 className="zw_text_color poppins-regular zw_16">
                                  {patient.Firstname}
                                </h3>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div
                    className="group-1261154931-mvt"
                    variant="secondary"
                    onClick={handleSecondModalShow}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      className="group-1261154930-5gg"
                      src="/images/group-1261154930.png"
                      alt="group-1261154930"
                    />

                    <div className="add-patient-DH6 bg-transparent border-0">
                      Add patient
                    </div>
                  </div>
                </div>
                <p className="select-type-report-W1J">Select type report</p>
                <div className="auto-group-ipwr-QMa">
                  <div className="auto-group-7r4k-LW8">
                    <p className="radiology-UcL">Radiology</p>
                    <div className="rectangle-39649-nd2"></div>
                    <img
                      className="i-radiology-svgrepo-com-2-79W"
                      src="/images/i-radiology-svgrepo-com-2.png"
                      alt="i-radiology-svgrepo-com-2"
                    />
                  </div>
                  <div className="auto-group-tp4g-pZi">
                    <p className="lab-research-A7n">Lab research</p>
                    <div className="rectangle-39650-4yr"></div>
                    <img
                      className="group-1261154900-bTz"
                      src="/images/group-1261154900.png"
                      alt="group-1261154900"
                    />
                  </div>
                  <div className="auto-group-g7mn-KPz">
                    <p className="prescription-4sN">Prescription</p>
                    <div className="rectangle-39651-aqi"></div>
                    <img
                      className="group-1261154902-Jmi"
                      src="/images/group-1261154902.png"
                      alt="group-1261154902"
                    />
                  </div>
                </div>

                <div className="group-1261154830-K6U my-5">
                  <p className="file-name-fRE poppins-medium zw_16">
                    File name
                  </p>
                  <div className="auto-group-prhw-onL">
                    <input
                      className="poppins-medium zw_16 zw_9B9B9B"
                      type="text"
                      placeholder=""
                      style={{
                        marginLeft: "-550px",

                        width: "550px",
                      }}
                    />
                  </div>
                </div>
                <div className="group-1261154831-5E4">
                  <div className="auto-group-ojic-E6x">
                    <p
                      className="attachments-mMn"
                      style={{ marginTop: "25px" }}
                    >
                      Attachments
                    </p>
                    <div className="d-flex align-items-end">
                      <div className="group-1261154826-Jcc me-3">
                        <div className="group-1261154825-EFN">
                          <label
                            htmlFor="file-upload"
                            className="group-1261154824-yye"
                          >
                            <img
                              src="/images/group-1261154824.png"
                              alt="group-1261154824"
                              style={{ width: "55px", marginTop: "10px" }}
                            />
                            <p className="upload-Knc py-3">Upload</p>
                          </label>
                          <input
                            id="file-upload"
                            type="file"
                            multiple // Allow multiple files
                            style={{ display: "none" }}
                            onChange={handleFileUpload}
                          />
                        </div>
                      </div>

                      <div className="uploaded-files-container d-flex gap-2">
                        {uploadedFiles.map((file, index) => (
                          <div key={index} className="file-thumbnail position-relative">
                            {file.file.type.includes("image") ? (
                              <img
                                src={file.url}
                                alt="Thumbnail"
                                style={{ width: "50px", height: "50px" }}
                              />
                            ) : (
                              <img
                                src="/images/PDFIcon.svg" // Replace with PDF icon URL
                                alt="PDF Icon"
                                style={{ width: "50px", height: "50px" }}
                              />
                            )}
                            <button
                              className="btn-close-red position-absolute top-0 end-0 border-0 rounded"
                              style={{ backgroundColor: '#ffffff' }}
                              onClick={() => handleRemoveFile(index)}
                              aria-label="Close">❌</button>

                          </div>
                        ))}
                      </div>
                    </div>


                  </div>
                  <div
                    className="group-1261154827-pzG"
                    style={{ marginTop: "30px" }}
                  >
                    <div className="group-1261154832-ayS " onHide={handleFirstModalClose}>Save</div>
                  </div>
                </div>



              </div>
            </div>
          </div>

          <Modal
            className="addpattientsecond mt-5"
            show={showSecondModal}
            onHide={handleSecondModalClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>Add Patients</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* <p>This is the second popup.</p> */}
              <form onSubmit={handleSubmitPatient} className="p-3">
                <div className="row mb-3 ">
                  <div className="col">
                    <label
                      htmlFor="Firstname"
                      className="zw_poppins_regular poppins-regular zw_20 zw_text_111535"
                    >
                      First Name
                    </label>
                    <input
                      type="text"
                      className="form-control form-controll-add-patient zw_form_control zw_secondary poppins-regular zw_16"
                      id="firstName"
                      name="Firstname"
                      placeholder="Enter first name"
                      onChange={handleInputChange}
                    />
                    {errors.Firstname && (
                      <div className="text-danger">{errors.Firstname}</div>
                    )}
                  </div>
                  <div className="col">
                    <label
                      htmlFor="Lastname"
                      className="zw_poppins_regular poppins-regular zw_20 zw_text_111535"
                    >
                      Last Name
                    </label>
                    <input
                      type="text"
                      className="form-control form-controll-add-patient zw_form_control zw_secondary poppins-regular zw_16"
                      id="lastName"
                      name="Lastname"
                      placeholder="Enter last name"
                      onChange={handleInputChange}
                    />
                    {errors.Lastname && (
                      <div className="text-danger">{errors.Lastname}</div>
                    )}
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col">
                    <label
                      htmlFor="Country"
                      className="zw_poppins_regular poppins-regular zw_20 zw_text_111535"
                    >
                      Country
                    </label>

                    <ReactFlagsSelect
                      onChange={handleInputChange}
                      selected={selected}
                      onSelect={handleCountrySelect}
                      placeholder="Select Country"
                      searchable
                      searchPlaceholder="Search countries"
                      className="menu-flags"

                    />
                    {errors.Country && (
                      <div className="text-danger">{errors.Country}</div>
                    )}
                  </div>
                  <div className="col">
                    <label
                      htmlFor="Nationalid"
                      className="zw_poppins_regular poppins-regular zw_20 zw_text_111535"
                    >
                      National ID
                    </label>
                    <input
                      type="text"
                      className="form-control form-controll-add-patient zw_form_control zw_secondary poppins-regular zw_16"
                      id="nationalId"
                      name="Nationalid"
                      placeholder="Enter national ID"
                      onChange={handleInputChange}
                    />
                    {errors.Nationalid && (
                      <div className="text-danger">{errors.Nationalid}</div>
                    )}
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col">
                    <label
                      htmlFor="Gender"
                      className="zw_poppins_regular poppins-regular zw_20 zw_text_111535"
                    >
                      Gender
                    </label>

                    <i className="icon-down-arrow form-icon zw_icon_drop mt-5"></i>
                    <select
                      onChange={handleInputChange}
                      id="gender"
                      name="Gender"
                      className="form-control form-controll-add-patient zw_form_control zw_secondary poppins-regular zw_16"
                    >
                      <option value="">Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </select>
                    {errors.Gender && (
                      <div className="text-danger">{errors.Gender}</div>
                    )}
                  </div>
                  <div className="col">
                    <label
                      htmlFor="Dob"
                      className="zw_poppins_regular poppins-regular zw_20 zw_text_111535"
                    >
                      Date of Birth
                    </label>
                    <input
                      type="date"
                      className="form-control form-controll-add-patient zw_form_control zw_secondary poppins-regular zw_16"
                      id="dob"
                      name="Dob"
                      placeholder="Enter date of birth"
                      onChange={handleInputChange}
                    />
                    {errors.Dob && <div className="text-danger">{errors.Dob}</div>}
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col">
                    <label
                      htmlFor="BloodGroup"
                      className="zw_poppins_regular poppins-regular zw_20 zw_text_111535"
                    >
                      Blood Group
                    </label>

                    <div>
                      <i className="icon-down-arrow form-icon zw_icon_drop mt-5"></i>
                      <select
                        onChange={handleInputChange}
                        id="bloodGroup"
                        name="Bloodgroup"
                        className="form-control form-controll-add-patient zw_form_control zw_secondary poppins-regular zw_16"
                      >
                        <option value="">Select Blood Group</option>
                        <option value="A+">A+</option>
                        <option value="A-">A-</option>
                        <option value="AB+">AB+</option>
                        <option value="AB-">AB-</option>
                        <option value="B+">B+</option>
                        <option value="B-">B-</option>
                        <option value="O+">O+</option>
                        <option value="O-">O-</option>
                      </select>
                      {errors.Bloodgroup && (
                        <div className="text-danger">{errors.Bloodgroup}</div>
                      )}
                    </div>
                  </div>
                  <div className="col">
                    <label
                      htmlFor="Phone"
                      className="zw_poppins_regular poppins-regular zw_20 zw_text_111535"
                    >
                      Phone
                    </label>
                    <input
                      type="tel"
                      className="form-control form-controll-add-patient zw_form_control zw_secondary poppins-regular zw_16"
                      id="phone"
                      name="Phone"
                      placeholder="Enter phone number"
                      onChange={handleInputChange}
                    />
                    {errors.Phone && (
                      <div className="text-danger">{errors.Phone}</div>
                    )}
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col">
                    <label
                      htmlFor="Relationship"
                      className="zw_poppins_regular poppins-regular zw_20 zw_text_111535"
                    >
                      Relationship
                    </label>
                    <i className="icon-down-arrow form-icon zw_icon_drop mt-5"></i>
                    <select
                      onChange={handleInputChange}
                      id="relationship"
                      name="Relationship"
                      className="form-control form-controll-add-patient zw_form_control zw_secondary poppins-regular zw_16 "
                    >
                      <option key="" value="">
                        Select Relationship
                      </option>
                      <option key="" value="Father">
                        Father
                      </option>
                      <option key="" value="Mother">
                        Mother
                      </option>
                      <option key="" value="Brother">
                        Brother
                      </option>
                      <option key="" value="Sister">
                        Sister
                      </option>
                      <option key="" value="Son">
                        Son
                      </option>
                      <option key="" value="Other">
                        Other
                      </option>
                    </select>
                    {errors.Relationship && (
                      <div className="text-danger">{errors.Relationship}</div>
                    )}
                  </div>
                  <div className="col">
                    <label
                      htmlFor="InsuranceCompany"
                      className="zw_poppins_regular poppins-regular zw_20 zw_text_111535"
                    >
                      Insurance Company
                    </label>
                    <input
                      type="text"
                      className="form-control form-controll-add-patient zw_form_control zw_secondary poppins-regular zw_16"
                      id="insuranceCompany"
                      name="InsuranceCompany"
                      placeholder="Enter insurance company"
                      onChange={handleInputChange}
                    />
                    {errors.InsuranceCompany && (
                      <div className="text-danger">{errors.InsuranceCompany}</div>
                    )}
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col">
                    <label
                      htmlFor="InsuranceAccNo"
                      className="zw_poppins_regular poppins-regular zw_20 zw_text_111535"
                    >
                      Insurance Account Number
                    </label>
                    <input
                      type="text"
                      className="form-control form-controll-add-patient zw_form_control zw_secondary poppins-regular zw_16"
                      id="insuranceAccNo"
                      name="InsuranceAccNo"
                      placeholder="Enter insurance account number"
                      onChange={handleInputChange}
                    />
                    {errors.InsuranceAccNo && (
                      <div className="text-danger">{errors.InsuranceAccNo}</div>
                    )}
                  </div>
                </div>

                <div className="d-grid gap-2 col-6 mx-auto mt-5">
                  <button
                    className="bt btn-primar zw_bg_gradient py-4 border-0 rounded poppins-regular zw_14 zw_text_fff "
                    type="submit"
                    variant="secondary"

                  >
                    Submit
                  </button>
                </div>
              </form>
            </Modal.Body>
            <Modal.Footer>
              {/* <Button variant="secondary" onClick={handleSecondModalClose}>
                Close
              </Button> */}
            </Modal.Footer>
          </Modal>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleFirstModalClose}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
    </div>
  );
};

export default Addrecord;
