import React, { useState, useContext,useEffect } from 'react';
import '../../Style/VaccinationList.css';
import { Link, useNavigate } from 'react-router-dom';
import Navbar from "../Layout/Navbar";
import Footer from "../Layout/Footer";

// images
import vaccine2mon from "../../images/e1.png";

import vaccine4mon from "../../images/h1.png";
import vaccine6mon from "../../images/e1.png";

import close from "../../images/close.svg"

import { Context } from '../../Context';
import MapLocationPop from '../MapLocationPop';
function VitaminVIDrips() {

  const [selectedTest, setSelectedTest] = useState([]);

  const [query, setQuery] = useState('');
  

  const handleSearchInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);

    
  };

  const handleToggleSelect = (item) => {
    if (selectedTest.includes(item)) {
      setSelectedTest(prevSelected => prevSelected.filter(selected => selected !== item));
    } else {
      setSelectedTest(prevSelected => [...prevSelected, item]);
    }
  }

  // const handleChecked = (item, index) => {

  //     setSelectedTest(prevSelectedItem => {
  //         if (prevSelectedItem.includes(item)) {
  //             return prevSelectedItem.filter(i => i !== item);
  //         } else {
  //             return [...prevSelectedItem, item];
  //         }
  //     });
  //     setCount((prevCounts) => {
  //         const newCounts = [...prevCounts];
  //         newCounts[index] = 1;
  //         return newCounts;
  //     });
  // };

  // const handleSelectedTest = (testTitle) => {

  //     setSelectedTest(prevSelectedItem => {
  //         if (prevSelectedItem.includes(testTitle)) {
  //             const idx = prevSelectedItem.indexOf(testTitle)
  //             prevSelectedItem[idx] = undefined;
  //             return [prevSelectedItem];
  //         } else {
  //             return [...prevSelectedItem, testTitle];
  //         }
  //     });
  // };

  // const incrementCount = (index) => {
  //     setCount((prevCounts) => {
  //         const newCounts = [...prevCounts];
  //         newCounts[index] += 1;
  //         return newCounts;
  //     });
  // };

  // Function to handle decrementing count
  // const decrementCount = (index) => {
  //     setCount((prevCounts) => {
  //         const newCounts = [...prevCounts];
  //         if (newCounts[index] > 1) {
  //             newCounts[index] -= 1;
  //         }
  //         return newCounts;
  //     });
  // };
  
  const { appointmentData, updateAppointmentData,show, setShow } = useContext(Context);
  const [zoneData, setZoneData] = useState([]);  
  const [locationName, setLocationName] = useState('');
  const navigate = useNavigate();

  const fetchZoneData = async (latitude, longitude) => {
    try {
      const response = await fetch(
        `https://zwarra.biztechnosys.com/api/fetchZoneData/${latitude}/${longitude}`,
        {
          headers: {
            "Content-Type": "application/json",
            Cookie: "zwarra_session=8svaEnKXoPHya4NjfgtmI4XABhWqWjVpkmz53q2L",
          },
        }
      );
      const data = await response.json();
      setZoneData(data); // Set the fetched zone data to state
      console.log("Zone Data:", data); // Log the zone data
    } catch (error) {
      console.error("Error fetching zone data:", error);
    }
  };

  const showLocation = (loc) => {
    setLocationName(loc);
    updateAppointmentData({ Address: loc });
    const latitude = 45.07187238118124;
    const longitude = 26.286879877969852;
    fetchZoneData(latitude, longitude);
  };
  
  const [VitaminIVs1Data, setVitaminsVs1Data] = useState([]);
        useEffect(() => {
            fetch(
            "https://zwarra.biztechnosys.com/api/subservices?id=6&servicetype=single"
            )
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                setVitaminsVs1Data(data);
            });
        }, []);
      const handlenext = async (e) => {
          e.preventDefault();
          if (selectedTest.length === 0) return; // Prevent continuation without selection
          console.log("Step 2 data", appointmentData);
          const subservicesJson = JSON.stringify(selectedTest.map(test => (test.Enname)));
          updateAppointmentData({ Subservices: subservicesJson });
          navigate("/vitamincenter");
        };

  return (
    <div>
      <Navbar />
      <div className="vaccination" style={{ marginTop: "50px" }}>

        <div className="container-17">
          <div className="group-1261154093">
            <Link to={'/'}>
              <div className="d-flex">
                <img className="group-1261154072" src="/images/Group 1261154072.png" alt='' />
                <span className="back poppins-medium zw_18 zw_black">
                  Back
                </span>
              </div>
            </Link>
            <div className="line_indicator_container">
              <div className="each_line_indicator active">
              </div>
              <div className="each_line_indicator">
              </div>
              <div className="each_line_indicator">
              </div>
              <div className="each_line_indicator">
              </div>
            </div>
          </div>
          <div className="vaccination-list poppins-semibold zw_34 zw_text_color">
            Packages
          </div>
          <div className="container-13 gap-5">
                    <div className="col-5  frames-37119 p-4">
                    <div className='align-self-center d-flex select-location-hover' onClick={() => setShow(true)}>
                      <img className="grouped-1" src="/images/location.png" alt='' />
                      <div className="ms-3 poppins-semibold zw_14 zw_title_color" >Selected Location</div>
                    </div>
                    <div className="lined-1"></div>
                    <div>
                      <span className="poppins-semibold zw_14 zw_title_color">{appointmentData.Address}</span>
                      {/* <img className="vector-19" src="/images/downarrow.png" alt='' /> */}
                    </div>
                  </div>
            <div class="search-container">
              <form style={{ display: 'flex' }}>
                <input
                  style={{ width: '40rem' }}
                  type="text"
                  className="search-input zw_16 poppins-regular"
                  placeholder="Search "
                  value={query}
                  onChange={handleSearchInputChange}
                />
                <button class="search-button zw_bgwhite">
                  <i class="icon-search zw_black "></i>
                </button>
              </form>
            </div>
          </div>
        </div>

        <div className="container-4">
          <div>
            {VitaminIVs1Data.length > 0 ? (
              VitaminIVs1Data?.map((item, index) => (
                <div className='children-vaccination-card' key={item?.Enname}>
                  <div className='vaccination-box'>
                    {/* {console.log("data: " ,selectedTest[index], index)} */}
                    <div className={`select_box ${selectedTest.includes(item) ? 'select-box-bg' : ''}`}
                      onClick={() => handleToggleSelect(item)}></div>
                    <div className='children-vaccination'>
                      <img className='' src={`https://zwarra.biztechnosys.com/${item?.Logo}`} alt="" />
                      <div className='ms-4'>
                        <div className='childrens-vaccination-age-2-month poppins-bold zw_24 zw_text_color' style={{ marginLeft: 0, marginTop: 0 }}>{item?.Enname}</div>
                        <div className='vaccination-subtext poppins-regular zw_16 zw_secondary'>{item?.Erdescription}</div>
                        <p className="poppins-regular zw_16 zw_title_color instructions" >{item?.Eninstrucation}  </p>
                      </div>
                    </div>
                    <div className='vaccination-details'>
                      <span className="poppins-regular zw_16 zw_title_color">
                        {item?.Ardescription}
                      </span>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className='no-data-found poppins-semibold zw_18 zw_text_color'>
                No data found
              </div>
            )}
          </div>

         

          <div className='ms-4'>
            <div className="selected-test poppins-semibold zw_16 zw_text_color">
              Selected test
            </div>
            <div className="container-11">
              {selectedTest?.map((item, index) => (
                <div className="frame-1261154252">
                  <div className="group-1261154161">
                    <span className="poppins-regular zw_12 zw_title_color">
                      {item?.Enname}
                    </span>
                  </div>
                  <button className="component-1" onClick={() => handleToggleSelect(item)}>
                    <img src={close} style={{ height: "100%", width: "100%" }} alt='' />
                  </button>
                </div>
              ))}
              {/* <Link to="/Selectlabs">
                                <button className="frame-37121 poppins-regular zw_14 zw_text_fff">
                                    Continue
                                </button>
                            </Link> */}
              {/* <Link to={`${selectedTest?.length !== 0 ? '/vitamincenter' : ''}`}> */}
                <button className={`frame-37121 poppins-regular zw_14 zw_text_fff ${selectedTest?.length === 0 ? 'disabled' : ''}`} onClick={handlenext}>
                  Continue
                </button>
              {/* </Link> */}
            </div>
            {/* <Link to={`${selectedTest?.length !== 0 ? '/Selectcenter' : ''}`}>
                            <button className={`frame-37121 poppins-regular zw_14 zw_text_fff ${selectedTest?.length === 0 ? 'disabled' : ''}`}>
                                Continue
                            </button>
                        </Link> */}
          </div>
        </div>
        {show ? <MapLocationPop path={"samePage"} locName={showLocation} /> : ""}
      </div>
      <Footer />
    </div>
  )
}

export default VitaminVIDrips
